import { FC, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAjax } from "../../../hooks/ajax";
import StepFlow from "../../../molecules/StepFlow";

const Register: FC<{ complated: (email: string) => void }> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>();
  const ajax = useAjax(false);
  const onSubmit = async (data: { email: string }) => {
    const { email } = data;
    await ajax.post("/api/members/email", { email });
    props.complated(email);
  };
  return (
    <Container>
      <h2>会員登録</h2>
      <StepFlow
        labels={["メール登録", "メール確認", "会員情報登録", "確認待ち"]}
        current={1}
      />
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>メールアドレス</Form.Label>
          <Form.Control
            type="email"
            placeholder="please@entry.mail"
            {...register("email", {
              required: "メールアドレスを指定してください",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "メールアドレスを入力してください",
              },
            })}
            isInvalid={!!errors.email}
          />
          {errors.email && (
            <Form.Text className="text-danger">
              {errors.email.message}
            </Form.Text>
          )}
        </Form.Group>

        <div className="text-end">
          <Button variant="primary" type="submit">
            登録
          </Button>
        </div>
      </Form>
    </Container>
  );
};

const Registered: FC<{ email: string }> = (props) => {
  return (
    <Container>
      <h2>会員登録</h2>
      <StepFlow
        labels={["メール登録", "メール確認", "会員情報登録", "確認待ち"]}
        current={1}
        complated={true}
      />
      <p>
        登録されたメールアドレスを確認します。
        <br />
        {props.email} に確認用のメールが送られているはずです。
      </p>
    </Container>
  );
};

const RegisterEmail: FC = () => {
  const [registeredEmail, setRegisteredEmail] = useState("");
  return (
    <>
      {!registeredEmail ? (
        <Register
          complated={(email) => {
            setRegisteredEmail(email);
          }}
        />
      ) : (
        <Registered email={registeredEmail} />
      )}
    </>
  );
};

export default RegisterEmail;
