import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BasicPageBase from "../../templates/BasicPageBase";

const title = "利用規約";
const description = "利用規約に関する情報を公開しているページです。";

const TermsOfService: FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <BasicPageBase>
      <Helmet>
        <title>{title} | confidence cigar</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Container className="mt-4">
        <h3 className="mb-5">利用規約</h3>
        <p className="mb-4">
          confidence-cigar.com（以下、当サイト）へのご訪問ありがとうございます。
          <br />
          当サイトをご利用される前に以下の「利用規約」をお読みください。
          <br />
          当サイトのご利用は以下の規約に同意して頂く必要があります。
        </p>
        <dl className="mb-5">
          <dt className="mb-2">規約の変更</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社は、当社が必要と判断する場合、当サイトの目的の範囲内で、本規約を変更することができます。
            <br />
            その場合、当社は、変更後の本規約の内容および効力発生日を、本サービスもしくは当サイトに表示し、または当社が定める方法により利用者に通知することで周知します。
            <br />
            変更後の本規約は、効力発生日から効力を生じるものとします。
            <br />
            当社は、単独及び絶対の裁量で、コンテンツ及び装置を含むサイトの一部を変更、修正、追加または削除する権利を留保します。
          </dd>
          <dt className="mb-2">卸売価格の変動に関して</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当サイトの卸売価格は市場在庫や為替変動の影響を受けます。
            <br />
            そのため、お見積り毎に価格が変動する可能性があります。
          </dd>
          <dt className="mb-2">ユーザコメント・提案等</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当サイト、当社、及び当社が管理するSNSに提出されたすべてのコメント、提案等は
            株式会社confidence の財産となります。
          </dd>
          <dt className="mb-2">税金・ライセンス</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当サイトにてご購入された際に発生するすべての税およびライセンスは、利用者の責任となります。
            <br />
            当サイトは保留中の税金、またはライセンスを持つ任意の注文に対して責任を負わないものといたします。
          </dd>
          <dt className="mb-2">著作権、商標権、その他知的財産権</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当サイトは 株式会社confidence（以下、当社） により運営されています。
            <br />
            注釈が無い限り、サイトの一部である画像、イラスト、デザイン、アイコン、写真、及びその他の書かれた材料を含む全ての素材は、株式会社confidence
            が所有、制御、ライセンス保護を行っています。
            <br />
            その他のすべての商標、サービスマーク、製品名、会社名またはサイト上に表示されるロゴの権利はそれぞれの所有者に帰属します。
            <br />
            同所有者の書面による事前の許可なしに商標、サービスマーク、製品名、会社名やロゴの使用または複製、変更、配布と再発行を含む行為は禁じられています。
          </dd>
          <dt className="mb-2">法令遵守</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当サイトは、満20歳未満の方には商品を販売致しません。
            <br />
            当サイトのご利用は日本在住の満20歳以上かつ個人事業主、または法人に限らせて頂きます。
            <br />
            当サイトを通じて、もしくは当サイトの情報を併記して日本国内法に違反する内容、誹謗中傷、好ましくない内容、他者の権利侵害、犯罪推奨等の内容を送信することを禁止します。
            <br />
            当社は上記を行うものに対してアカウントの停止、アクセスの禁止、法的対処などを行う権利を有します。
          </dd>
          <dt className="mb-2">免責補償</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当サイト利用によって生じた機器の障害、故障などに関して当社は一切の責任を負いません。
            <br />
            <br />
            当サイトの全ての商品は適切な温湿度を行った上での提供を保証しますが、ハンドメイド商品の性質上、輸送や製造、保管時に品質に一定の揺らぎが発生する可能性があります。
            <br />
            同品質問題による返金は行いません。
            <br />
            <br />
            当社は、当サイトに関する瑕疵（セキュリティ等に関する欠陥、エラーやバグ、権利侵害を含みます。）がないこと、ならびに安全性、信頼性、正確性、完全性、有効性および特定の目的への適合性を明示的にも黙示的にも保証していません。
            <br />
            当社は、利用者に対して、かかる瑕疵を除去して当サイトのサービスを提供する義務を負いません。
            <br />
            <br />
            当サイトに掲載されている商品が在庫切れの場合にはご注文をお断りする場合があります。
            <br />
            仕入れ中の場合にはご注文をお受けすることが可能ですが、発送までお時間を頂くことがあります。
            <br />
            また仕入れ後当社にて検査を行いますが、商品に問題が確認された場合は発送までさらにお時間を頂くことがあります。
            <br />
            <br />
            当社は、当社の故意または重過失に起因する場合を除き、当サイトを利用し直接的、間接的に生じたいかなる損害、費用、利益の損失、業務への支障等に関して一切責任を負いません。
            <br />
            当社の最高賠償責任額は紛争中の取引に提供される商品の請求額を上回らないものとします。
            <br />
            <br />
            当社は第三者からの不正アクセス、不正なアプリケーションを介しての情報の盗難、紛失、破壊、改ざんに関して責任を負いません。
            <br />
            当サイトのご利用は利用者の責任においてのみ行われるものとします。
          </dd>
          <dt className="mb-2">連絡方法</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            メールにてお願い致します。
            <br />
            <br />
            contact
            <FontAwesomeIcon icon={faStar} />
            confidence-cigar.com
            <br />
            <small className="text-muted">
              ※ 実際にメールを送る際は「
              <FontAwesomeIcon icon={faStar} />
              」を「@」に置き換えてください
            </small>
          </dd>
          <dt className="mb-2">返金・返品に関して</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            不良・欠陥品である場合に限り、商品到着後3日以内にメール、またはお電話にてお知らせください。
            <br />
            またご依頼商品が当社にて取り扱い不可能となった場合、ご連絡後対応させて頂きます。
          </dd>
          <dt className="mb-2">セキュリティ</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社は利用者の個人情報を安全に管理するため、SSL技術を使用し情報のやり取りを行います。
            <br />
            収拾した個人情報はプライバシーポリシーに記載された内容以外での使用は致しません。
          </dd>
          <dt className="mb-2">準拠法</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            日本法とします。
            <br />
            当サイトに起因または関連して利用者と当社との間に生じた紛争については東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </dd>
        </dl>
        <p className="mb-5 text-end">最終更新日: 2022年03月29日</p>
      </Container>
    </BasicPageBase>
  );
};

export default TermsOfService;
