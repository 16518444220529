import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BasicPageBase from "../../templates/BasicPageBase";

const title = "プライバシーポリシー（個人情報保護方針）";
const description =
  "プライバシーポリシー（個人情報保護方針）に関する情報を公開しているページです。";

const PrivacyPolicy: FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <BasicPageBase>
      <Helmet>
        <title>{title} | confidence cigar</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Container className="mt-4">
        <h3 className="mb-5">プライバシーポリシー（個人情報保護方針）</h3>
        <p className="mb-4">
          株式会社confidence（以下、「当社」という。）は、ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。
          <br />
          本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。
        </p>
        <dl className="mb-5">
          <dt className="mb-2">1. 事業者情報</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            法人名: 株式会社confidence
            <br />
            住所: 東京都大田区羽田３−１−７
            <br />
            代表者: 松浦 大樹
          </dd>
          <dt className="mb-2">2. 個人情報の取得方法</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社はユーザーが利用登録をするとき、氏名・生年月日・住所・電話番号・メールアドレス、事業情報など個人、事業を特定できる情報を取得させていただきます。
            <br />
            お問い合わせの送信時には、氏名・電話番号・メールアドレスを取得させていただきます。
          </dd>
          <dt className="mb-2">3. 個人情報の利用目的</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社は取得した個人情報を次に挙げる事項を行うために利用します。
            <br />
            <ol className="ps-4">
              <li>ユーザーに本サービスを提供するため</li>
              <li>商品のお見積りや発注、発送を行うため</li>
              <li>新商品の入荷情報送信のため</li>
              <li>仕入れ商品のご要望をお伺いするため</li>
            </ol>
          </dd>
          <dt className="mb-2">4. 個人データの第三者提供について</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社は法令及びガイドラインに別段の定めがある場合を除き、本人の同意を得ないで第三者に個人情報を提供することは致しません。
          </dd>
          <dt className="mb-2">5. 保有個人データの開示、訂正</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社は本人から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。
            <br />
            個人情報の利用目的の通知や訂正、追加、削除、利用の停止、第三者への提供の停止を希望される方は以下の手順でご請求ください。
            <br />
            （以下のメールアドレス宛に件名を「個人情報の開示・訂正の件」としてお送り下さい）
            <br />
            <br />
            contact
            <FontAwesomeIcon icon={faStar} />
            confidence-cigar.com
            <br />
            <small className="text-muted">
              ※ 実際にメールを送る際は「
              <FontAwesomeIcon icon={faStar} />
              」を「@」に置き換えてください
            </small>
          </dd>
          <dt className="mb-2">
            6. 個人情報取り扱いに関する相談や苦情の連絡先
          </dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせはメールにてご連絡ください。
            <br />
            （以下のメールアドレス宛に件名を「個人情報の取り扱いの件」としてお送り下さい）
            <br />
            <br />
            contact
            <FontAwesomeIcon icon={faStar} />
            confidence-cigar.com
            <br />
            <small className="text-muted">
              ※ 実際にメールを送る際は「
              <FontAwesomeIcon icon={faStar} />
              」を「@」に置き換えてください
            </small>
          </dd>
          <dt className="mb-2">7. SSL（Secure Socket Layer）について</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。
          </dd>
          <dt className="mb-2">8. cookieについて</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            cookieは、ユーザーが confidence-cigar.com
            にどのような経路で訪問されたか、当サイト内での行動を分析させて頂くために使用されます。
            <br />
            cookieには決済などの情報が含まれることはありません。
            <br />
            またcookieは、お使いのコンピュータ上で保存され、唯一
            confidence-cigar.com で読み取ることができます。
          </dd>
          <dt className="mb-2">9. ポリシーの改定</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            本ポリシーは、随時改定されることがあります。
            <br />
            変更後の本ポリシーは、ユーザーに対してわかりやすい方法で告知するものとし、当該告知がなされた時点からその効力を生じるものとします。
          </dd>
          <dt className="mb-2">10. プライバシーポリシーの制定日及び改定日</dt>
          <dd className="mb-3 ms-1 ms-sm-3">制定: 2022年03月29日</dd>
          <dt className="mb-2">11. 免責事項</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社Webサイトに掲載されている情報の正確性には万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。
            <br />
            当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
          </dd>
          <dt className="mb-2">12. 著作権・肖像権</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。
          </dd>
          <dt className="mb-2">13. リンク</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            当社Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンクの設置をお断りすることがあります。
          </dd>
        </dl>
      </Container>
    </BasicPageBase>
  );
};

export default PrivacyPolicy;
