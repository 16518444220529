import { FC, useEffect } from "react";
import { Container, Alert } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import BasicPageBase from "../../templates/BasicPageBase";
import {
  faFileArrowDown,
  faStar,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const title = "ご注文の流れ";
const description = "ご注文の流れに関する情報を公開しているページです。";

const FlowToOrder: FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location.hash]);
  return (
    <BasicPageBase>
      <Helmet>
        <title>{title} | confidence cigar</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Container className="mt-4">
        <h3 className="mb-5">ご注文の流れ</h3>
        <p className="mb-4">
          商品の選択、お見積もりの依頼から商品の受け取りまでの流れになります。
        </p>
        <ol className="mb-4">
          <li>
            <HashLink to="#select" className="text-decoration-none text-body">
              商品の選択
            </HashLink>
          </li>
          <li>
            <HashLink
              to="#estimation"
              className="text-decoration-none text-body"
            >
              お見積もりメールの送信
            </HashLink>
          </li>
          <li>
            <HashLink
              to="#confirmation"
              className="text-decoration-none text-body"
            >
              お見積もりの確認
            </HashLink>
          </li>
          <li>
            <HashLink to="#order" className="text-decoration-none text-body">
              発注書の送付
            </HashLink>
          </li>
          <li>
            <HashLink to="#transfer" className="text-decoration-none text-body">
              お振込
            </HashLink>
          </li>
          <li>
            <HashLink
              to="#acceptance"
              className="text-decoration-none text-body"
            >
              商品の受領
            </HashLink>
          </li>
        </ol>
        <div className="border p-3 rounded mb-4" id="select">
          <h5 className="fw-bold">商品の選択</h5>
          <p>
            商品の一覧、詳細ページで商品の内容をご確認ください。
            <br />
            お見積もりの際には商品の情報が必要となります。お手数ですが名称をご確認ください。
          </p>
          <img
            src="/flow-to-order-select.png"
            className="img-thumbnail col-md-5"
            alt="商品の選択イメージ"
          />
        </div>
        <div className="border p-3 rounded mb-4" id="estimation">
          <h5 className="fw-bold">お見積もりメールの送信</h5>
          <p>下記の内容でメールを送付ください。</p>
          <Alert variant="warning">
            <Alert.Heading className="h5">
              <FontAwesomeIcon icon={faTriangleExclamation} className="me-1" />
              初回のお取引の方へ
            </Alert.Heading>
            <p className="mb-0">
              開業届出済証明書（個人事業主）、もしくは現在事項全部証明書（法人）が必要です。
              <br />
              画像（jpg、gif）、もしくはpdfでの添付をお願い致します。
            </p>
          </Alert>
          <dl>
            <dt>宛先メールアドレス</dt>
            <dd className="ms-2">
              contact
              <FontAwesomeIcon icon={faStar} />
              confidence-cigar.com
              <br />
              <small className="text-muted">
                ※ 実際にメールを送る際は「
                <FontAwesomeIcon icon={faStar} />
                」を「@」に置き換えてください
              </small>
            </dd>
            <dt>メール件名</dt>
            <dd className="ms-2">
              <span className="text-muted">
                例: お見積について（株式会社 confidence）
              </span>
              <br />
              <small className="text-muted">
                ※ （）には法人名、個人事業主の方は屋号か氏名
              </small>
            </dd>
            <dt>メール本文</dt>
            <dd className="ms-2">
              <p>
                【事業形態】
                <br />
                <span className="text-muted ms-3">例: 法人</span>
                <br />
                <small className="text-muted ms-3">
                  ※ 法人、もしくは個人事業主
                </small>
                <br />
                【名称】
                <br />
                <span className="text-muted ms-3">例: 株式会社 confidence</span>
                <br />
                <small className="text-muted ms-3">
                  ※ 法人名、個人事業主の方は屋号か氏名
                </small>
                <br />
                【姓名】
                <br />
                <span className="text-muted ms-3">例: 今譜井 伝助</span>
                <br />
                <small className="text-muted ms-3">※ 担当者様の姓名</small>
                <br />
                【セイメイ】
                <br />
                <span className="text-muted ms-3">例: コンフィ デンスケ</span>
                <br />
                <small className="text-muted ms-3">※ 担当者様のセイメイ</small>
                <br />
                【生年月日】
                <br />
                <span className="text-muted ms-3">例: 1987年01月23日</span>
                <br />
                <small className="text-muted ms-3">※ 担当者様の生年月日</small>
                <br />
                【住所】
                <br />
                <span className="text-muted ms-3">例: 〒123-4567</span>
                <br />
                <span className="text-muted ms-4">
                  東京都○○区1丁目2番3号 ○○ビル101
                </span>
                <br />
                <small className="text-muted ms-3">
                  ※ 建物名などもご記載ください
                </small>
                <br />
                【お届け先住所】
                <br />
                <span className="text-muted ms-3">例: 住所と同上</span>
                <br />
                <small className="text-muted ms-3">
                  ※ 住所と違う場合はご記載ください
                </small>
                <br />
                【Eメールアドレス】
                <br />
                <span className="text-muted ms-3">
                  例: taro.conf
                  <FontAwesomeIcon icon={faAt} />
                  confidence-cigar.com
                </span>
                <br />
                <small className="text-muted ms-3">
                  ※ 日中に連絡がつくアドレスをご記載ください
                </small>
                <br />
                【電話番号】
                <br />
                <span className="text-muted ms-3">例: 09012345678</span>
                <br />
                <small className="text-muted ms-3">
                  ※ 日中に連絡がつく電話番号をご記載ください
                </small>
                <br />
                【商品内容・本数（箱数）】
                <br />
                <span className="text-muted ms-3">
                  例: ガンタナメラ デシモス 1箱
                </span>
                <br />
                <span className="text-muted ms-4">
                  モンテクリスト No.5 2021年6月製造 ばら売り 3本
                </span>
                <br />
                <small className="text-muted ms-3">
                  ※ お見積もり対象の商品、数量などご記載ください
                </small>
                <br />
                【その他】
                <br />
                <span className="text-muted ms-3">
                  例: ○○○○について確認させてください。
                </span>
                <br />
                <small className="text-muted ms-3">
                  ※ その他、ご不明点などはこちらにご記載ください
                </small>
              </p>
            </dd>
            <dt>添付</dt>
            <dd className="ms-2">
              初回のお取引の方のみ
              <strong>開業届出済証明書（個人事業主）</strong>
              、もしくは<strong>現在事項全部証明書（法人）</strong>が必要です。
              <br />
              画像（jpg、gif）、もしくはpdfでの添付をお願い致します。
            </dd>
          </dl>
        </div>
        <div className="border p-3 rounded mb-4" id="confirmation">
          <h5 className="fw-bold">お見積もりの確認</h5>
          <p>
            当社からお見積り書をEメールアドレス宛に返信致します。
            <br />
            ご不明点などはお見積もりメールの宛先までお問合せください。
          </p>
        </div>
        <div className="border p-3 rounded mb-4" id="order">
          <h5 className="fw-bold">発注書の送付</h5>
          <p>
            お見積り書の内容に問題がなければ注文書にご記載の上、メールにてお送りください。
          </p>
          <dl>
            <dt>宛先メールアドレス</dt>
            <dd className="ms-2">
              contact
              <FontAwesomeIcon icon={faStar} />
              confidence-cigar.com
              <br />
              <small className="text-muted">
                ※ 実際にメールを送る際は「
                <FontAwesomeIcon icon={faStar} />
                」を「@」に置き換えてください
              </small>
            </dd>
            <dt>メール件名</dt>
            <dd className="ms-2">
              <span className="text-muted">
                例: 発注について（株式会社 confidence）
              </span>
              <br />
              <small className="text-muted">
                ※ （）には法人名、個人事業主の方は屋号か氏名
              </small>
            </dd>
            <dt>メール本文</dt>
            <dd className="ms-2">
              <span className="text-muted">例: 発注書を送付します。</span>
            </dd>
            <dt>添付</dt>
            <dd className="ms-2">
              <p>
                発注書にご記載の上、添付ください。発注書は下記のいずれかのサンプルをご利用ください。
              </p>
              <div className="ms-2">
                <a
                  href="/発注書サンプル.xls"
                  download
                  className="text-decoration-none"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} className="me-1" />
                  発注書サンプル（Excel）
                </a>
                <br />
                <a
                  href="/発注書サンプル.ods"
                  download
                  className="text-decoration-none"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} className="me-1" />
                  発注書サンプル（OpenOffice）
                </a>
              </div>
            </dd>
          </dl>
        </div>
        <div className="border p-3 rounded mb-4" id="transfer">
          <h5 className="fw-bold">お振込</h5>
          <p>
            発注書を送付頂いたら3日以内に銀行口座にお振込ください。
            <br />
            3日以内にお振込みが確認できない場合、キャンセル扱いとさせていただきます。
            <br />
            <small className="text-muted">
              ※ 口座情報はお見積時に連携致します
            </small>
          </p>
        </div>
        <div className="border p-3 rounded mb-4" id="acceptance">
          <h5 className="fw-bold">商品の受領</h5>
          <p>お振込み確認後3営業日以内にゆうパックにて発送致します。</p>
        </div>
      </Container>
    </BasicPageBase>
  );
};

export default FlowToOrder;
