import { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faBanSmoking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GuardModal: FC<{ show: boolean; onHide: () => void }> = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">年齢確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center text-danger mb-3">
          <FontAwesomeIcon icon={faBanSmoking} size="7x" />
        </h4>

        <p>
          未成年者の喫煙は法律で禁止されており、未成年者に対して販売はしていません。たばこの販売に際しては、公的証明書による年齢確認及び本人確認を実施しております。
        </p>
        <p className="fw-bold">あなたは日本在住の満20歳以上の喫煙者ですか？</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center d-grid d-sm-flex gap-2">
        <Button onClick={props.onHide} size="lg" variant="secondary">
          <strong>はい</strong>
          <small className="ms-1">20歳以上です</small>
        </Button>
        <Link to="/rejected" className="btn btn-lg btn-secondary">
          <strong>いいえ</strong>
          <small className="ms-1">20歳未満です</small>
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

function getTwentyAndOver() {
  const twentyAndOverStr = localStorage.getItem("twenty-and-over");
  if (!twentyAndOverStr) {
    return undefined;
  }
  return JSON.parse(twentyAndOverStr) as boolean;
}

function setTwentyAndOver(value: boolean) {
  localStorage.setItem("twenty-and-over", JSON.stringify(value));
}

let twentyAndOver = getTwentyAndOver();

const TwentyAndOverGuardedRoute: FC = (props) => {
  const [modalShow, setModalShow] = useState(!twentyAndOver);
  const onHide = () => {
    twentyAndOver = true;
    setTwentyAndOver(twentyAndOver);
    setModalShow(false);
  };
  return (
    <>
      {props.children}
      <GuardModal show={modalShow} onHide={onHide} />
    </>
  );
};

export default TwentyAndOverGuardedRoute;
