import { FC, useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  ListGroup,
  Pagination,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAjax } from "../../../hooks/ajax";
import { useNavigate, useLocation } from "react-router-dom";
import { parse } from "query-string";
import AdminPageBase from "../../../templates/AdminPageBase";

type MemberStatus = "PENDING_VERIFICATION" | "CONFIRMED";

type MemberEntity = {
  id: number;
  status: MemberStatus;
  purchasingPersonnel: { lastName: string; firstName: string };
};
type MembersResponse = { count: number; memberEntities: MemberEntity[] };

const Pagenation: FC<{
  page: number;
  count?: number;
  take: number;
  onChange?: (page: number) => void;
}> = (props) => {
  console.log(props.count);
  if (!props.count) {
    return null;
  }
  const lastPage =
    Math.floor(props.count / props.take) + (props.count % props.take ? 1 : 0);
  console.log(lastPage);

  const aaa = (list: number[]) => {
    return list.map((elem) => (
      <Pagination.Item
        key={elem}
        active={props.page === elem}
        onClick={() => {
          if (props.page === elem) {
            return;
          }
          props.onChange && props.onChange(elem);
        }}
      >
        {elem}
      </Pagination.Item>
    ));
  };

  if (lastPage <= 5) {
    const test = () => {
      const aaa = [];
      for (let idx = 1; idx <= lastPage; idx++) {
        aaa.push(
          <Pagination.Item
            key={idx}
            active={props.page === idx}
            onClick={() => {
              if (props.page === idx) {
                return;
              }
              props.onChange && props.onChange(idx);
            }}
          >
            {idx}
          </Pagination.Item>
        );
      }
      return aaa;
    };
    return (
      <div>
        <Pagination className="justify-content-center">
          {/* <Pagination.First
            disabled={props.page === 1}
            onClick={() => {
              props.onChange && props.onChange(1);
            }}
          /> */}
          <Pagination.Prev
            disabled={props.page === 1}
            onClick={() => {
              props.onChange && props.onChange(props.page - 1);
            }}
          />
          {test()}
          <Pagination.Next
            disabled={props.page === lastPage}
            onClick={() => {
              props.onChange && props.onChange(props.page + 1);
            }}
          />
          {/* <Pagination.Last
            disabled={props.page === lastPage}
            onClick={() => {
              props.onChange && props.onChange(lastPage);
            }}
          /> */}
        </Pagination>
      </div>
    );
  }

  if (props.page <= 3) {
    return (
      <div>
        <Pagination className="justify-content-center">
          <Pagination.Prev
            disabled={props.page === 1}
            onClick={() => {
              props.onChange && props.onChange(props.page - 1);
            }}
          />
          {aaa([1, 2, 3, 4])}
          <Pagination.Ellipsis disabled />
          {aaa([lastPage])}
          <Pagination.Next
            disabled={props.page === lastPage}
            onClick={() => {
              props.onChange && props.onChange(props.page + 1);
            }}
          />
        </Pagination>
      </div>
    );
  }
  if (props.page >= lastPage - 2) {
    return (
      <div>
        <Pagination className="justify-content-center">
          <Pagination.Prev
            disabled={props.page === 1}
            onClick={() => {
              props.onChange && props.onChange(props.page - 1);
            }}
          />
          {aaa([1])}
          <Pagination.Ellipsis disabled />
          {aaa([lastPage - 3, lastPage - 2, lastPage - 1, lastPage])}
          <Pagination.Next
            disabled={props.page === lastPage}
            onClick={() => {
              props.onChange && props.onChange(props.page + 1);
            }}
          />
        </Pagination>
      </div>
    );
  }
  return (
    <div>
      <Pagination className="justify-content-center">
        <Pagination.Prev
          disabled={props.page === 1}
          onClick={() => {
            props.onChange && props.onChange(props.page - 1);
          }}
        />
        {aaa([1])}
        <Pagination.Ellipsis disabled />
        {aaa([props.page - 1, props.page, props.page + 1])}
        <Pagination.Ellipsis disabled />
        {aaa([lastPage])}
        <Pagination.Next
          disabled={props.page === lastPage}
          onClick={() => {
            props.onChange && props.onChange(props.page + 1);
          }}
        />
      </Pagination>
    </div>
  );
  // if (!props.count || !props.page || !props.take) {

  //   return (
  //     <Pagenation>
  //       <Pagination.First />
  //       <Pagination.Prev />
  //       <Pagination.Item>{1}</Pagination.Item>
  //       <Pagination.Ellipsis disabled />
  //       <Pagination.Next />
  //       <Pagination.Last />
  //     </Pagenation>
  //   );
  //   // console.log("aaa")
  // }
  // return (
  //   <Pagination>
  //     <Pagination.First />
  //     <Pagination.Prev />
  //     <Pagination.Item>{1}</Pagination.Item>
  //     <Pagination.Ellipsis disabled />

  //     <Pagination.Item>{10}</Pagination.Item>
  //     <Pagination.Item>{11}</Pagination.Item>
  //     <Pagination.Item active>{12}</Pagination.Item>
  //     <Pagination.Item>{13}</Pagination.Item>
  //     <Pagination.Item disabled>{14}</Pagination.Item>

  //     <Pagination.Ellipsis />
  //     <Pagination.Item>{20}</Pagination.Item>
  //     <Pagination.Next />
  //     <Pagination.Last />
  //   </Pagination>
  // );
};

const List: FC = () => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    // formState: { errors },
  } = useForm<{
    email: string;
    password: string;
    statuses: { pendingVerification: boolean; confirmed: boolean };
  }>({
    reValidateMode: "onChange",
    defaultValues: {
      statuses: { pendingVerification: true, confirmed: true },
    },
  });
  const ajax = useAjax();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const { q } = parse(location.search) as { q: string };
      if (!q) {
        return;
      }
      const { page, take, statuses } = JSON.parse(q) as {
        page: number;
        take: number;
        statuses: { pendingVerification: boolean; confirmed: boolean };
      };
      const filter = {
        statuses: [] as string[],
      };
      // const statuses = getValues("statuses");
      if (statuses.pendingVerification) {
        filter.statuses.push("PENDING_VERIFICATION");
      }
      if (statuses.confirmed) {
        filter.statuses.push("CONFIRMED");
      }
      const res = await ajax.get<MembersResponse>("/api/operator/members", {
        params: { filter, take, skip: take * (page - 1) },
      });
      reset({ statuses });
      setMembers(res.data.memberEntities);
      setCount(res.data.count);
      setPage(page);
      setTake(take);
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const navigate = useNavigate();
  const [members, setMembers] = useState<MemberEntity[]>([]);

  const onSubmit = async (data: {
    email: string;
    password: string;
    statuses: { pendingVerification: boolean; confirmed: boolean };
  }) => {
    const query = { ...data, page: 1, take };
    navigate({
      search: `?q=${JSON.stringify(query)}`,
    });
  };

  return (
    <AdminPageBase backTo="/admin" pageTitle="[管理]会員一覧">
      <Container>
        <h2>会員一覧</h2>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="checkbox-1"
              label="確認待ち"
              {...register("statuses.pendingVerification")}
            />
          </div>
          <div className="mb-3">
            <Form.Check
              type="checkbox"
              id="checkbox-2"
              label="確認済"
              {...register("statuses.confirmed")}
            />
          </div>
          {/* <Form.Group className="mb-3" controlId="email">
          <Form.Label>メールアドレス</Form.Label>
          <Form.Control
            type="email"
            placeholder="please@entry.mail"
            {...register("email", {
              required: "メールアドレスを指定してください",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "メールアドレスを入力してください",
              },
            })}
            isInvalid={!!errors.email}
          />
          {errors.email && (
            <Form.Text className="text-danger">
              {errors.email.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>パスワード</Form.Label>
          <Form.Control
            type="password"
            placeholder="password"
            {...register("password", {
              required: "パスワードを指定してください",
            })}
            isInvalid={!!errors.password}
          />
          {errors.password && (
            <Form.Text className="text-danger">
              {errors.password.message}
            </Form.Text>
          )}
        </Form.Group> */}
          <div className="text-end">
            <Button variant="primary" type="submit">
              検索
            </Button>
          </div>
        </Form>
        <Form.Select
          aria-label="Default select example"
          value={take}
          onChange={async (e) => {
            const { statuses } = getValues();
            const query = { statuses, page: 1, take: e.target.value };
            navigate({
              search: `?q=${JSON.stringify(query)}`,
            });
          }}
        >
          <option value={10}>10</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </Form.Select>
        <Pagenation
          page={page}
          count={count}
          take={take}
          onChange={async (page) => {
            const { statuses } = getValues();
            const query = { statuses, page, take };
            navigate({
              search: `?q=${JSON.stringify(query)}`,
            });
          }}
        />
        {members && (
          <ListGroup>
            {members.map((member, idx) => (
              <ListGroup.Item
                key={idx}
                onClick={() => {
                  const data = getValues();
                  const query = { ...data, page, take };
                  navigate({
                    pathname: `/admin/members/${member.id}`,
                    search: `?q=${JSON.stringify(query)}`,
                  });
                }}
              >
                {member.id} {member.purchasingPersonnel.lastName}{" "}
                {member.purchasingPersonnel.firstName}
                {member.status}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Container>
    </AdminPageBase>
  );
};

export default List;
