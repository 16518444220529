import { FC, useEffect, useState } from "react";
import { Container, Alert, Card, Button } from "react-bootstrap";
import BasicPageBase from "../../templates/BasicPageBase";
import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAjax } from "../../hooks/ajax";
import { getCarts, setCart } from "../products/ProductDetail";
import { Product } from "../products/Products";

const title = "カート";
const description = "カートの中身を確認できるページです。";

const Cart: FC = () => {
  const ajax = useAjax(false);
  const [products, setProducts] = useState<(Product & { num: number })[]>([]);
  const [hasNotCartItems, setHasNotCartItems] = useState(false);

  useEffect(() => {
    (async () => {
      const carts = getCarts();
      if (Object.keys(carts).length === 0) {
        setHasNotCartItems(true);
        return;
      }
      const products = await ajax.get<Product[]>("/api/anyone/products", {
        params: {
          ids: Object.keys(carts).join(","),
        },
      });
      setProducts(
        products.data.map((product) =>
          Object.assign(product, carts[product.id])
        )
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTotal = () => {
    if (products.length === 0) {
      return 0;
    }
    return products
      .map((product) =>
        Math.floor(
          getRealProductPriceAndTax(product).price *
            product.num *
            (1 + getRealProductPriceAndTax(product).tax / 100)
        )
      )
      .reduce((sum, elem) => sum + elem);
  };

  const getRealProductPriceAndTax = (product: Product) => {
    if (product.specialPrice && product.specialPriceTax) {
      return {
        price: product.specialPrice,
        tax: product.specialPriceTax,
      };
    }
    return {
      price: product.price,
      tax: product.priceTax,
    };
  };

  return (
    <BasicPageBase>
      <Helmet>
        <title>{title} | confidence cigar</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Container className="mt-4">
        <h3 className="mb-3">カート</h3>
        {hasNotCartItems && (
          <Alert variant="secondary">商品が選択されていません。</Alert>
        )}
        <div className="row flex-md-row-reverse">
          <div className="col-md-4">
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>合計(税込)</Card.Title>
                <Card.Text>{getTotal().toLocaleString()} 円</Card.Text>
                <div className="d-grid gap-2">
                  <Link
                    to="/quotation"
                    className={`btn btn-warning${
                      products.length === 0 ? " disabled" : ""
                    }`}
                  >
                    見積もりへ
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-8">
            {products.map((product) => (
              <Card key={product.id}>
                <Card.Body>
                  <div className="d-flex flex-column flex-sm-row">
                    <div>
                      <Card.Img
                        src={`/api/anyone/products/images/${product.featuredImageFileName}_350x350`}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                    <div className="ps-sm-3">
                      <Card.Title>{product.name}</Card.Title>
                      <Card.Text
                        className={`${
                          product.specialPrice && product.specialPriceTax
                            ? "mb-0 text-decoration-line-through"
                            : "mb-1"
                        }`}
                      >
                        国内定価{" "}
                        {Math.floor(
                          product.price * (1 + product.priceTax / 100)
                        ).toLocaleString()}{" "}
                        円
                      </Card.Text>
                      {product.specialPrice && product.specialPriceTax && (
                        <Card.Text className="mb-1 text-danger">
                          特価{" "}
                          {Math.floor(
                            product.specialPrice *
                              (1 + product.specialPriceTax / 100)
                          ).toLocaleString()}{" "}
                          円
                        </Card.Text>
                      )}
                      <Card.Text className="mb-0">数量 {product.num}</Card.Text>
                      <Card.Text>
                        小計{" "}
                        {Math.floor(
                          getRealProductPriceAndTax(product).price *
                            product.num *
                            (1 + getRealProductPriceAndTax(product).tax / 100)
                        ).toLocaleString()}{" "}
                        円
                      </Card.Text>
                      <div>
                        <Button
                          variant="outline-secondary"
                          className="rounded-circle"
                          size="sm"
                          disabled={product.num <= 1}
                          onClick={() => {
                            const curNum = product.num;
                            setProducts((products) => {
                              const newProducts = [...products];
                              newProducts.filter(
                                (target) => target.id === product.id
                              )[0].num = curNum - 1;
                              return newProducts;
                            });
                            setCart(product.id, { num: curNum - 1 });
                          }}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </Button>
                        <Button
                          variant="outline-secondary"
                          className="rounded-circle"
                          size="sm"
                          disabled={product.num >= product.stockQuantity}
                          onClick={() => {
                            const curNum = product.num;
                            setProducts((products) => {
                              const newProducts = [...products];
                              newProducts.filter(
                                (target) => target.id === product.id
                              )[0].num = curNum + 1;
                              return newProducts;
                            });
                            setCart(product.id, { num: curNum + 1 });
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                        <Button
                          variant="outline-secondary"
                          className="rounded-circle"
                          size="sm"
                          style={{ width: "30.25px" }}
                          onClick={() => {
                            const curProductsNum = products.length;
                            setProducts((products) => {
                              return [
                                ...products.filter(
                                  (target) => target.id !== product.id
                                ),
                              ];
                            });
                            setCart(product.id, { num: 0 });
                            if (curProductsNum === 1) {
                              setHasNotCartItems(true);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </Container>
    </BasicPageBase>
  );
};

export default Cart;
