import { run as runHolder } from "holderjs";
import { FC, useEffect, useState } from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAjax } from "../../hooks/ajax";
import BasicPageBase from "../../templates/BasicPageBase";
import "./Products.scss";

export type Product = {
  id: number;
  name: string;
  supplementaryInformation?: string;
  price: number;
  priceTax: number;
  edition?: string;
  specialPrice?: number;
  specialPriceTax?: number;
  stockQuantity: number;
  inStockSoon?: boolean;
  onlyFewLeft?: boolean;
  featuredImageFileName?: string;
};

const Products: FC = () => {
  const [products, setProducts] = useState<Product[]>([]);

  const ajax = useAjax(false);

  useEffect(() => {
    (async () => {
      const ret = await ajax.get("/api/anyone/products");
      setProducts(ret.data);
      const myImage = document.getElementsByClassName(
        "holder-img"
      ) as unknown as HTMLElement;
      runHolder({ images: myImage });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const label = (product: Product) => {
    const {
      stockQuantity,
      inStockSoon,
      specialPrice: salePrice,
      onlyFewLeft,
    } = product;
    if (stockQuantity === 0) {
      if (inStockSoon) {
        return (
          <div className="label">
            売り切れ<span>(入荷中)</span>
          </div>
        );
      }
      return (
        <div className="label">
          売り切れ<span>(入荷未定)</span>
        </div>
      );
    }
    if (onlyFewLeft) {
      return <div className="label only-few-left">残りわずか</div>;
    }
    if (salePrice) {
      return <div className="label sale">セール</div>;
    }
    return null;
  };

  return (
    <BasicPageBase mainClassName="products">
      <div className="carousel-wrapper">
        <Carousel className="top">
          <Carousel.Item>
            <img
              className="d-block w-100 h-100"
              src="/top.jpg"
              alt="confidence cigar"
            />
            <Carousel.Caption>
              <h3 className="h6">最高のひとときをあなたに</h3>
              <p className="small">
                confidence cigar は高品質な葉巻を
                <br className="d-block d-sm-none" />
                ご提供致します
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          {/* <Carousel.Item>
            <img
              className="d-block w-100 holder-img"
              src="holder.js/800x400?text=First slide&bg=373940"
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 holder-img"
              src="holder.js/800x400?text=Second slide&bg=282c34"
              alt="Second slide"
            />

            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 holder-img"
              src="holder.js/800x400?text=Third slide&bg=20232a"
              alt="Third slide"
            />

            <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption>
          </Carousel.Item> */}
        </Carousel>
      </div>

      <Container>
        <Row className="mt-4">
          {products.map((product, idx) => (
            <Col key={idx} xs={6} md={4} lg={3} className="mb-3 item">
              <div className="products">
                <Link
                  to={`/products/${product.id}`}
                  className="link-dark text-decoration-none"
                >
                  <Card>
                    {product.featuredImageFileName ? (
                      <Card.Img
                        variant="top"
                        src={`/api/anyone/products/images/${product.featuredImageFileName}`}
                        className="holder-img img-fluid h-auto w-100"
                      />
                    ) : (
                      <Card.Img
                        variant="top"
                        src="/no_image.svg"
                        className="img-fluid h-auto w-100"
                      />
                    )}
                    <Card.Body>
                      {product.edition && (
                        <Card.Text className="edition">
                          {product.edition}
                        </Card.Text>
                      )}
                      <Card.Title>{product.name}</Card.Title>
                      {product.supplementaryInformation && (
                        <Card.Text className="text-muted supplementary-information">
                          {product.supplementaryInformation}
                        </Card.Text>
                      )}
                      <Card.Text
                        className={`price mb-0${
                          product.specialPrice
                            ? " text-decoration-line-through"
                            : ""
                        }`}
                      >
                        国内定価:{" "}
                        {Math.floor(
                          product.price * (1 + product.priceTax / 100)
                        ).toLocaleString()}
                        円
                      </Card.Text>
                      {product.specialPrice !== undefined &&
                        product.specialPriceTax !== undefined && (
                          <Card.Text className="special-price mb-0">
                            特価:{" "}
                            {Math.floor(
                              product.specialPrice *
                                (1 + product.specialPriceTax / 100)
                            ).toLocaleString()}
                            円
                          </Card.Text>
                        )}
                      <Card.Text className="estimate-price">
                        お見積り価格: お問い合わせください
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  {!product.stockQuantity && <div className="overlay"></div>}
                  {label(product)}
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </BasicPageBase>
  );
};
export default Products;
