import { FC, useEffect, useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import moment from "moment";
import {
  useForm,
  UseFormRegister,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { getToken, useAjax } from "../../../hooks/ajax";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AdminPageBase from "../../../templates/AdminPageBase";

type MemberEntity = {
  id: number;
  purchasingPersonnel: { lastName: string; firstName: string };
};
type MembersResponse = { count: number; memberEntities: MemberEntity[] };

const map = {
  PENDING_VERIFICATION: "確認待ち",
  CONFIRMED: "確認済",
} as { [key: string]: string };

const genderMap = {
  "1": "女性",
  "2": "男性",
  "3": "どちらでもない",
  "4": "回答しない",
} as { [key: string]: string };

const Test: FC<{
  getValues: UseFormGetValues<FieldValues>;
}> = (props) => {
  const { getValues } = props;
  const getName = () => {
    const lastName = getValues("purchasingPersonnel.lastName");
    const firstName = getValues("purchasingPersonnel.firstName");
    if (!lastName && !firstName) {
      return "";
    }
    return `${lastName} ${firstName}`;
  };
  const getNameKana = () => {
    const lastName = getValues("purchasingPersonnel.lastNameKana");
    const firstName = getValues("purchasingPersonnel.firstNameKana");
    if (!lastName && !firstName) {
      return "";
    }
    return `${lastName} ${firstName}`;
  };
  const getBirthday = () => {
    const birthday = getValues("purchasingPersonnel.birthday");
    if (!birthday) {
      return "";
    }
    return moment(birthday).format("西暦 YYYY 年 MM 月 DD 日");
  };
  return (
    <>
      <div className="card bg-light mb-4">
        <div className="card-body">
          <h5 className="card-title">販売担当者情報</h5>
          <div className="mt-4">
            <Row>
              <Col sm={6}>
                <Form.Group
                  className="mb-3"
                  controlId="purchasingPersonnel-name"
                >
                  <Form.Label>姓名</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={getName()}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group
                  className="mb-3"
                  controlId="purchasingPersonnel-namekana"
                >
                  <Form.Label>セイメイ</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={getNameKana()}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  className="mb-3"
                  controlId="purchasingPersonnel-gender"
                >
                  <Form.Label>性別</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={
                      genderMap[getValues("purchasingPersonnel.gender")]
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group
                  className="mb-3"
                  controlId="purchasingPersonnel-birthday"
                >
                  <Form.Label>生年月日</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={getBirthday()}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="purchasingPersonnel-tel">
              <Form.Label>電話番号</Form.Label>
              <Form.Control
                type="text"
                // plaintext={true}
                readOnly={true}
                defaultValue={getValues("purchasingPersonnel.tel")}
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
};

const Test2: FC<{ getValues: UseFormGetValues<FieldValues> }> = (props) => {
  const { getValues } = props;
  return (
    <>
      <div className="card bg-light mb-4">
        <div className="card-body">
          <h5 className="card-title">事業者情報</h5>
          <div className="mt-4">
            <Form.Group className="mb-3" controlId="corporation-no">
              <Form.Label>事業者番号</Form.Label>
              <Form.Control
                type="text"
                // plaintext={true}
                readOnly={true}
                defaultValue={getValues("corporation.no")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="corporation-name">
              <Form.Label>事業者名</Form.Label>
              <Form.Control
                type="text"
                // plaintext={true}
                readOnly={true}
                defaultValue={getValues("corporation.name")}
              />
            </Form.Group>
            <h6>住所</h6>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="corporation-postCode">
                  <Form.Label>郵便番号</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={getValues("corporation.postCode")}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group
                  className="mb-3"
                  controlId="corporation-prefectures"
                >
                  <Form.Label>都道府県</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={getValues("corporation.prefectures")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="corporation-address1">
                  <Form.Label>市区町村</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={getValues("corporation.address1")}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="corporation-address2">
                  <Form.Label>番地</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={getValues("corporation.address2")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="corporation-address3">
              <Form.Label>建物名・部屋番号</Form.Label>
              <Form.Control
                type="text"
                // plaintext={true}
                readOnly={true}
                defaultValue={getValues("corporation.address3")}
              />
            </Form.Group>
            <ImagePreviews getValues={getValues} />
          </div>
        </div>
      </div>
    </>
  );
};

const ImagePreviews: FC<{ getValues: UseFormGetValues<FieldValues> }> = (
  props
) => {
  const { getValues } = props;
  const corporationImages = getValues("corporation.corporationImages");
  const [token, setToken] = useState<string>();
  useEffect(() => {
    (async () => {
      setToken(await getToken());
    })().catch((err) => {
      console.error(err);
    });
  });
  return (
    <>
      <h6>画像</h6>
      {corporationImages &&
        corporationImages.map((image: { filename: string }, idx: number) => (
          <img
            key={idx}
            className="img-fluid rounded mb-2"
            alt=""
            src={`/api/operator/members/images/${image.filename}?token=${token}`}
          />
        ))}
    </>
  );
};

const StatusEditor: FC<{
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  onClickCancel: () => void;
}> = (props) => {
  const { getValues, setValue, register, onClickCancel } = props;
  const [status, setStatus] = useState("");
  useEffect(() => {
    setStatus(getValues("status"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onClickCancelInternal = () => {
    setValue("status", status);
    onClickCancel();
  };
  return (
    <>
      <div className="card bg-light mb-4">
        <div className="card-body">
          <div className="f-box">
            <div className="f-item">
              <h5 className="card-title">ID・状態</h5>
            </div>
            <div className="f-item me-1">
              <Button
                type="button"
                size="sm"
                variant="secondary"
                onClick={() => {
                  onClickCancelInternal();
                }}
              >
                取消
              </Button>
            </div>
            <div className="f-item">
              <Button type="submit" size="sm">
                保存
              </Button>
            </div>
          </div>
          <div className="mt-4">
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="id">
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={getValues("id")}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="status">
                  <Form.Label>状態</Form.Label>
                  <Form.Select {...register("status")}>
                    {Object.entries(map).map(([key, value], idx) => (
                      <option value={key} key={idx}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

const StatusViewer: FC<{
  getValues: UseFormGetValues<FieldValues>;
  onClickEdit: () => void;
}> = (props) => {
  const { getValues, onClickEdit } = props;
  return (
    <>
      <div className="card bg-light mb-4">
        <div className="card-body">
          <div className="f-box">
            <div className="f-item">
              <h5 className="card-title">ID・状態</h5>
            </div>
            <div className="f-item">
              <Button
                type="button"
                size="sm"
                variant="success"
                onClick={() => {
                  onClickEdit();
                }}
              >
                編集
              </Button>
            </div>
          </div>
          <div className="mt-4">
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="id">
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={getValues("id")}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="status">
                  <Form.Label>状態</Form.Label>
                  <Form.Control
                    type="text"
                    // plaintext={true}
                    readOnly={true}
                    defaultValue={map[getValues("status")]}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

const Detail: FC = () => {
  const [statusEdit, setStatusEdit] = useState(false);
  const {
    register,
    reset,
    getValues,
    setValue,
    handleSubmit,
    // formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const ajax = useAjax();
  const location = useLocation();
  useEffect(() => {
    (async () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const ret = await ajax.get(`/api/operator/members/${id}`);
      console.log(ret.data);
      reset(ret.data);
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const onSubmit = async (data: any) => {
    console.log(data);
    let req = {} as { status?: string };
    if (statusEdit) {
      req.status = data.status;
    }
    await ajax.put<MembersResponse>(`/api/operator/members/${id}`, req);
    setStatusEdit(false);
  };
  return (
    <AdminPageBase
      backTo={`/admin/members${location.search}`}
      pageTitle="[管理]会員詳細"
    >
      <Container>
        <h2>会員詳細</h2>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          {statusEdit ? (
            <StatusEditor
              getValues={getValues}
              setValue={setValue}
              register={register}
              onClickCancel={() => {
                setStatusEdit(false);
              }}
            />
          ) : (
            <StatusViewer
              getValues={getValues}
              onClickEdit={() => {
                setStatusEdit(true);
              }}
            />
          )}
          <Test getValues={getValues} />
          <Test2 getValues={getValues} />
        </Form>
        <div className="text-end">
          <Button
            variant="primary"
            type="button"
            onClick={() => {
              navigate({
                pathname: "/admin/members",
                search: `${location.search}`,
              });
            }}
          >
            戻る
          </Button>
        </div>
      </Container>
    </AdminPageBase>
  );
};

export default Detail;
