import {
  faAngleDown,
  faAngleUp,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosResponse } from "axios";
import { createContext, FC, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { ProductRegisterRequest } from "../../../../types/ProductRegisterRequest";
import { getToken, useAjax } from "../../../hooks/ajax";
import AdminPageBase from "../../../templates/AdminPageBase";
import {
  getCanvas,
  getImage,
  getResizeImageSize,
  toBlob,
} from "../../member/register/RegisterInfo";
import "./DropZone.scss";

type SpecForm = {
  shape: string;
  size: string;
  strength: string;
  time: string;
};

type ProductRegisterForm = {
  id: string;
  name: string;
  edition: string;
  supplementaryInformation: string;
  catchPhrase: string;
  description: string;
  price: string;
  priceTax: string;
  specialPrice: string;
  specialPriceTax: string;
  stockQuantity: string;
  stockQuantityUnit: string;
  inStockSoon: boolean;
  onlyFewLeft: boolean;
  spec: SpecForm;
  isOpen: boolean;
};

export const ToasterContext = createContext<{
  messages: {
    title: string;
    message: string;
  }[];
  setMessages: React.Dispatch<
    React.SetStateAction<
      {
        title: string;
        message: string;
      }[]
    >
  >;
}>({ messages: [], setMessages: () => {} });

export const Toaster: FC = (props) => {
  // const [show, setShow] = useState(false);
  const [messages, setMessages] = useState<
    { title: string; message: string }[]
  >([]);

  return (
    <>
      <ToasterContext.Provider value={{ messages, setMessages }}>
        {props.children}
      </ToasterContext.Provider>
      <ToastContainer
        className="p-3"
        containerPosition="fixed"
        position="top-end"
      >
        {messages.map(({ title, message }, idx) => (
          <Toast
            key={idx}
            onClose={() =>
              setMessages((messages) => {
                const ret = [...messages];
                ret.splice(idx, 1);
                return ret;
              })
            }
            show={true}
            delay={3000}
            bg="info"
            animation={true}
            autohide
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </>
  );
};

const Detail: FC = () => {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ProductRegisterForm>({
    reValidateMode: "onBlur",
  });
  const [
    priceFieldValue,
    priceTaxFieldValue,
    specialPriceFieldValue,
    specialPriceTaxFieldValue,
    specStrengthFieldValue,
    stockQuantityUnitFieldValue,
  ] = watch([
    "price",
    "priceTax",
    "specialPrice",
    "specialPriceTax",
    "spec.strength",
    "stockQuantityUnit",
  ]);
  // const { setMessages } = useContext(ToasterContext);
  const ajax = useAjax();
  const [featuredFile, setFeaturedFile] = useState<
    Blob & { name?: string; preview: string; remote?: boolean }
  >();
  const [isPriceWithTaxEntryMode, setPriceWithTaxEntryMode] = useState(false);
  const [isSpecialPriceWithTaxEntryMode, setSpecialPriceWithTaxEntryMode] =
    useState(false);
  const [files, setFiles] = useState<
    (Blob & { name?: string; preview: string; remote?: boolean })[]
  >([]);
  const {
    getRootProps: getFeaturedRootProps,
    getInputProps: getFeaturedInputProps,
  } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        console.error("画像１枚以外がしていされた");
        return;
      }
      const images = await Promise.all(
        acceptedFiles.map((file) => {
          return getImage(file);
        })
      );
      const uploadTargets = images.map((image, index) => {
        const resize = getResizeImageSize(image, {
          maxWidth: 650,
          maxHeight: 650,
        });
        if (image.width <= 650 && image.height <= 650) {
          return acceptedFiles[index];
        }
        return toBlob(getCanvas(image, resize), acceptedFiles[index].type);
      });

      setFeaturedFile(
        uploadTargets.map((target, idx) => {
          return Object.assign(target, {
            preview: URL.createObjectURL(target),
            name: acceptedFiles[idx].name,
          });
        })[0]
      );
    },
  });
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      const images = await Promise.all(
        acceptedFiles.map((file) => {
          return getImage(file);
        })
      );
      const uploadTargets = images.map((image, index) => {
        const resize = getResizeImageSize(image, {
          maxWidth: 1080,
          maxHeight: 1080,
        });
        if (image.width <= 1080 && image.height <= 1080) {
          return acceptedFiles[index];
        }
        return toBlob(getCanvas(image, resize), acceptedFiles[index].type);
      });
      setFiles((files) => {
        return [
          ...files,
          ...uploadTargets.map((target, idx) => {
            return Object.assign(target, {
              preview: URL.createObjectURL(target),
              name: acceptedFiles[idx].name,
            });
          }),
        ];
      });
    },
  });
  const { id } = useParams();
  const location = useLocation();
  useEffect(() => {
    (async () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const token = await getToken();
      const res = await ajax.get(`/api/operator/products/${id}`);
      const formData: ProductRegisterForm = {
        id: res.data.id,
        name: res.data.name,
        edition: res.data.edition,
        supplementaryInformation: res.data.supplementaryInformation,
        catchPhrase: res.data.catchPhrase,
        description: res.data.description,
        price: res.data.price.toString(),
        priceTax: res.data.priceTax.toString(),
        specialPrice: res.data.specialPrice?.toString(),
        specialPriceTax: res.data.specialPriceTax?.toString(),
        stockQuantity: res.data.stockQuantity.toString(),
        stockQuantityUnit: res.data.stockQuantityUnit,
        inStockSoon: res.data.inStockSoon,
        onlyFewLeft: res.data.onlyFewLeft,
        spec: {
          shape: res.data.specShape,
          size: res.data.specSize,
          strength: res.data.specStrength,
          time: res.data.specTime,
        },
        isOpen: res.data.isOpen,
      };
      if (res.data.featuredImageFileName) {
        setFeaturedFile({
          name: res.data.featuredImageFileName,
          remote: true,
          preview: `/api/operator/products/images/${res.data.featuredImageFileName}_350x350?token=${token}`,
        } as any);
      } else {
        setFeaturedFile(undefined);
      }

      setFiles(
        res.data.productImages.map((image: any) => {
          return {
            name: image.filename,
            remote: true,
            preview: `/api/operator/products/images/${image.filename}_350x350?token=${token}`,
          };
        })
      );
      reset(formData);
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = async (values: ProductRegisterForm) => {
    console.log(values);
    let featuredFileResult:
      | {
          data: {
            message: string;
            filename: string;
            notUpdated: boolean;
          };
        }
      | AxiosResponse<
          {
            message: string;
            filename: string;
          },
          any
        >
      | undefined = undefined;
    if (featuredFile) {
      if (featuredFile.remote) {
        featuredFileResult = {
          data: {
            message: "not updated",
            filename: featuredFile.name!,
            notUpdated: true,
          },
        };
      } else {
        const params = new FormData();
        params.append("image", featuredFile);
        featuredFileResult = await ajax.post<{
          message: string;
          filename: string;
        }>(`/api/operator/products/images`, params, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });
        featuredFile.remote = true;
        featuredFile.name = featuredFileResult.data.filename;
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // setMessages((messages) => [
      //   ...messages,
      //   { title: "ほべほべ", message: "こんにちは更新できたよ。" },
      // ]);
    }

    const results = await Promise.all(
      files.map((file) => {
        if (file.remote) {
          return Promise.resolve({
            data: {
              message: "not updated.",
              filename: file.name!,
              notUpdated: true,
            },
          });
        }
        const params = new FormData();
        params.append("image", file);
        return ajax.post<{ message: string; filename: string }>(
          `/api/operator/products/images`,
          params,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
      })
    );
    console.log(results);
    files.forEach((file, idx) => {
      if (!file.remote) {
        file.remote = true;
        file.name = results[idx].data.filename;
      }
    });

    const data: ProductRegisterRequest = {
      name: values.name,
      edition: values.edition || null,
      supplementaryInformation: values.supplementaryInformation || null,
      catchPhrase: values.catchPhrase || null,
      description: values.description,
      price: isPriceWithTaxEntryMode ? getCalcPrice() || 0 : +values.price,
      priceTax: +values.priceTax,
      specialPrice: isSpecialPriceWithTaxEntryMode
        ? getCalcSpecialPrice() || null
        : values.specialPrice
        ? +values.specialPrice
        : null,
      specialPriceTax: values.specialPriceTax ? +values.specialPriceTax : null,
      stockQuantity: +values.stockQuantity,
      stockQuantityUnit: values.stockQuantityUnit || null,
      inStockSoon: values.inStockSoon,
      onlyFewLeft: values.onlyFewLeft,
      spec: {
        shape: values.spec.shape || null,
        size: values.spec.size || null,
        strength: values.spec.strength || null,
        time: values.spec.time || null,
      },
      featuredImageFileName: featuredFileResult
        ? featuredFileResult.data.filename
        : null,
      images: results.map((result) => ({ filename: result.data.filename })),
      isOpen: values.isOpen,
    };
    await ajax.put(`/api/operator/products/${id}`, data);
    // navigate("/admin/products");
  };
  const removeFile = (index: number) => {
    setFiles((files) => {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
  const upFile = (index: number) => {
    setFiles((files) => {
      const newFiles = [...files];
      newFiles.splice(index - 1, 2, files[index], files[index - 1]);
      return newFiles;
    });
  };
  const downFile = (index: number) => {
    setFiles((files) => {
      const newFiles = [...files];
      newFiles.splice(index, 2, files[index + 1], files[index]);
      return newFiles;
    });
  };
  const featuredThumb = featuredFile ? (
    <div className="thumb-item">
      <div className="thumb-img">
        <img src={featuredFile.preview} alt="" />
      </div>
      <div className="thumb-right">
        <div className="thumb-operation text-end">
          <Button
            type="button"
            variant="outline-danger"
            onClick={() => {
              setFeaturedFile(undefined);
            }}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        </div>
        <div className="thumb-info mt-sm-3">
          <Row>
            <Col sm={4} className="small text-muted text-sm-end">
              ファイル名
            </Col>
            <Col sm={8} className="text-truncate">
              {featuredFile.name}
            </Col>
          </Row>
          {featuredFile.size && (
            <Row>
              <Col sm={4} className="small text-muted text-sm-end">
                サイズ
              </Col>
              <Col sm={8}>{featuredFile.size / 1000} KB</Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  ) : null;

  const thumbs = files.map((file, index) => (
    <div key={index} className="thumb-item">
      <div className="thumb-img">
        <img src={file.preview} alt="" />
      </div>
      <div className="thumb-right">
        <div className="thumb-operation text-end">
          <Button
            type="button"
            variant="outline-secondary me-1"
            onClick={() => upFile(index)}
            disabled={index === 0}
          >
            <FontAwesomeIcon icon={faAngleUp} />
          </Button>
          <Button
            type="button"
            variant="outline-secondary me-1"
            onClick={() => downFile(index)}
            disabled={index === files.length - 1}
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </Button>
          <Button
            type="button"
            variant="outline-danger"
            onClick={() => removeFile(index)}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        </div>
        <div className="thumb-info mt-sm-3">
          <Row>
            <Col sm={4} className="small text-muted text-sm-end">
              ファイル名
            </Col>
            <Col sm={8} className="text-truncate">
              {file.name}
            </Col>
          </Row>
          {file.size && (
            <Row>
              <Col sm={4} className="small text-muted text-sm-end">
                サイズ
              </Col>
              <Col sm={8}>{file.size / 1000} KB</Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  ));

  const getCalcPrice = () => {
    if (priceFieldValue && priceTaxFieldValue) {
      if (isPriceWithTaxEntryMode) {
        const price = +priceFieldValue;
        const taxRate = +priceTaxFieldValue / 100;
        const priceWithTax = Math.ceil(price / (1 + taxRate));
        return priceWithTax;
      } else {
        const price = +priceFieldValue;
        const taxRate = +priceTaxFieldValue / 100;
        const priceWithTax = Math.floor(price * (1 + taxRate));
        return priceWithTax;
      }
    } else {
      return undefined;
    }
  };

  const getCalcSpecialPrice = () => {
    if (specialPriceFieldValue && specialPriceTaxFieldValue) {
      if (isSpecialPriceWithTaxEntryMode) {
        const price = +specialPriceFieldValue;
        const taxRate = +specialPriceTaxFieldValue / 100;
        const priceWithTax = Math.ceil(price / (1 + taxRate));
        return priceWithTax;
      } else {
        const price = +specialPriceFieldValue;
        const taxRate = +specialPriceTaxFieldValue / 100;
        const priceWithTax = Math.floor(price * (1 + taxRate));
        return priceWithTax;
      }
    } else {
      return undefined;
    }
  };

  return (
    <AdminPageBase
      backTo={`/admin/products${location.search}`}
      pageTitle="[管理]商品詳細"
    >
      <Container>
        <h2>商品詳細</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="id">
            <Form.Label>ID</Form.Label>
            <Form.Control type="text" readOnly={true} {...register("id")} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>公開</Form.Label>
            <Form.Check
              type="switch"
              id="isOpen"
              label="公開済"
              {...register("isOpen")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>
              商品名
              <Badge bg="danger" className="ms-1">
                必須
              </Badge>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="例: ダビドフオロブランコ"
              isInvalid={!!errors.name}
              {...register("name", { required: "入力してください。" })}
            />
            {errors.name && (
              <div className="text-danger">{errors.name?.message}</div>
            )}
          </Form.Group>
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="edition">
                <Form.Label>エディション</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="例: 数量限定"
                  {...register("edition")}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="supplementaryInformation">
                <Form.Label>追加情報</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="例: 10本/箱"
                  {...register("supplementaryInformation")}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="catchPhrase">
            <Form.Label>キャッチフレーズ</Form.Label>
            <Form.Control
              type="text"
              placeholder="例: 今がお買い得。タイムセールはあと10日で終了"
              {...register("catchPhrase")}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="description">
            <Form.Label>
              説明
              <Badge bg="danger" className="ms-1">
                必須
              </Badge>
            </Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="例: オロ・ブランコはダビドフで最も高級な葉巻の一つです。マスターブレンダーのエラディオ・ディアスが開発したユニークな葉巻です。"
              style={{ height: "100px" }}
              isInvalid={!!errors.description}
              {...register("description", { required: "入力してください。" })}
            />
            {errors.description && (
              <div className="text-danger">{errors.description?.message}</div>
            )}
          </Form.Group>

          <div className="border bg-light p-3 mb-3">
            <div className="d-flex justify-content-between">
              <Form.Label>
                価格
                <Badge bg="danger" className="ms-1">
                  必須
                </Badge>
              </Form.Label>
              <div>
                <Form.Check
                  type="switch"
                  id="price-with-tax-entry-mode"
                  label="税込入力"
                  checked={isPriceWithTaxEntryMode}
                  onChange={() => {
                    setPriceWithTaxEntryMode((val) => !val);
                    setValue("price", getCalcPrice()?.toString() || "");
                  }}
                />
              </div>
            </div>

            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>
                    {isPriceWithTaxEntryMode ? "単価(税込)" : "単価(税抜)"}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="tel"
                      placeholder="例: 520000"
                      isInvalid={!!errors.price}
                      {...register("price", {
                        required: "入力してください。",
                      })}
                    />
                    <InputGroup.Text>円</InputGroup.Text>
                  </InputGroup>
                  {errors.price && (
                    <div className="text-danger">{errors.price?.message}</div>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="priceTax">
                  <Form.Label>税率</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="tel"
                      placeholder="例: 10"
                      isInvalid={!!errors.priceTax}
                      {...register("priceTax", {
                        required: "入力してください。",
                      })}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  {errors.priceTax && (
                    <div className="text-danger">
                      {errors.priceTax?.message}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="priceWithTax">
                  <Form.Label>
                    {isPriceWithTaxEntryMode
                      ? "税抜(自動計算)"
                      : "税込(自動計算)"}
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      readOnly={true}
                      value={getCalcPrice()?.toLocaleString() || ""}
                    />
                    <InputGroup.Text>円</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className="border bg-light p-3 mb-3">
            <div className="d-flex justify-content-between">
              <Form.Label>特価</Form.Label>
              <div>
                <Form.Check
                  type="switch"
                  id="special-price-with-tax-entry-mode"
                  label="税込入力"
                  checked={isSpecialPriceWithTaxEntryMode}
                  onChange={() => {
                    setSpecialPriceWithTaxEntryMode((val) => !val);
                    setValue(
                      "specialPrice",
                      getCalcSpecialPrice()?.toString() || ""
                    );
                  }}
                />
              </div>
            </div>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="specialPrice">
                  <Form.Label>
                    {isSpecialPriceWithTaxEntryMode
                      ? "単価(税込)"
                      : "単価(税抜)"}
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="tel"
                      placeholder="例: 519750"
                      {...register("specialPrice")}
                    />
                    <InputGroup.Text>円</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="specialPriceTax">
                  <Form.Label>税率</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="tel"
                      placeholder="例: 10"
                      {...register("specialPriceTax")}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="specialPriceWithTax">
                  <Form.Label>
                    {isSpecialPriceWithTaxEntryMode
                      ? "税抜(自動計算)"
                      : "税込(自動計算)"}
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      readOnly={true}
                      value={getCalcSpecialPrice()?.toLocaleString() || ""}
                    />
                    <InputGroup.Text>円</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className="border bg-light p-3 mb-3">
            <Form.Label>在庫</Form.Label>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="stockQuantity">
                  <Form.Label>在庫数</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="tel"
                      placeholder="例: 10"
                      {...register("stockQuantity")}
                    />
                    <InputGroup.Text>
                      {stockQuantityUnitFieldValue || "個"}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="stockQuantity">
                  <Form.Label>単位</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="例: 箱"
                      {...register("stockQuantityUnit")}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Form.Label>在庫ステータス</Form.Label>
            <Row>
              <Col sm={6}>
                <Form.Check
                  type="switch"
                  id="inStockSoon"
                  label="入荷中"
                  {...register("inStockSoon")}
                />
              </Col>
              <Col sm={6}>
                <Form.Check
                  type="switch"
                  id="onlyFewLeft"
                  label="残りわずか"
                  {...register("onlyFewLeft")}
                />
              </Col>
            </Row>
          </div>

          <div className="border bg-light p-3 mb-3">
            <Form.Label>スペック</Form.Label>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="specShape">
                  <Form.Label>形状</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="例: トロ (Toro)"
                    {...register("spec.shape")}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="specSize">
                  <Form.Label>サイズ</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='例: 6" x 54 RG'
                    {...register("spec.size")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="specStrength">
                  <Form.Label>強さ</Form.Label>
                  <Form.Select
                    aria-label="strength"
                    {...register("spec.strength")}
                    className={specStrengthFieldValue ? "" : "text-muted"}
                  >
                    <option value="">選択してください</option>
                    <option value="Mild">Mild</option>
                    <option value="Mild Medium">Mild Medium</option>
                    <option value="Medium">Medium</option>
                    <option value="Medium Full">Medium Full</option>
                    <option value="Full">Full</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="specTime">
                  <Form.Label>喫煙時間</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="例: 60分"
                    {...register("spec.time")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className="border bg-light p-3 mb-3">
            <Form.Group className="mb-3" controlId="featuredImages">
              <Form.Label>アイキャッチ画像</Form.Label>
              <div {...getFeaturedRootProps({ className: "dropzone mb-4" })}>
                <input {...getFeaturedInputProps()} />
                <p>
                  画像をドラッグアンドドロップ
                  <br />
                  またはクリック(タップ)してファイルを選択してください
                </p>
              </div>
              <aside>{featuredThumb}</aside>
            </Form.Group>
          </div>

          <div className="border bg-light p-3 mb-3">
            <Form.Group className="mb-3" controlId="images">
              <Form.Label>詳細画像</Form.Label>
              <div {...getRootProps({ className: "dropzone mb-4" })}>
                <input {...getInputProps()} />
                <p>
                  画像をドラッグアンドドロップ
                  <br />
                  またはクリック(タップ)してファイルを選択してください
                </p>
              </div>
              <aside>{thumbs}</aside>
            </Form.Group>
          </div>
          <div className="text-end">
            <Button type="submit">更新</Button>
          </div>
        </Form>
      </Container>
    </AdminPageBase>
  );
};

export default Detail;
