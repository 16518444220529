import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BasicPageBase from "../../templates/BasicPageBase";

const title = "当社について";
const description = "当社に関する情報を公開しているページです。";

const AboutUs: FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <BasicPageBase>
      <Helmet>
        <title>{title} | confidence cigar</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Container className="mt-4">
        <h3 className="mb-5">当社について</h3>
        <dl className="mb-5">
          <dt className="mb-2">法人名</dt>
          <dd className="mb-3 ms-1 ms-sm-3">株式会社confidence</dd>

          <dt className="mb-2">所在地</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            〒144-0043
            <br />
            東京都大田区羽田3-1-7
          </dd>
          <dt className="mb-2">代表者</dt>
          <dd className="mb-3 ms-1 ms-sm-3">松浦 大樹</dd>
          <dt className="mb-2">電話番号</dt>
          <dd className="mb-3 ms-1 ms-sm-3">080-6287-1024</dd>
          <dt className="mb-2">eメールアドレス</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            contact
            <FontAwesomeIcon icon={faStar} />
            confidence-cigar.com
            <br />
            <small className="text-muted">
              ※ 実際にメールを送る際は「
              <FontAwesomeIcon icon={faStar} />
              」を「@」に置き換えてください
            </small>
          </dd>
          <dt className="mb-2">ホームページアドレス</dt>
          <dd className="mb-3 ms-1 ms-sm-3">https://confidence-cigar.com/</dd>
          <dt className="mb-2">営業時間</dt>
          <dd className="mb-3 ms-1 ms-sm-3">09:00〜21:00</dd>
          <dt className="mb-2">定休日</dt>
          <dd className="mb-3 ms-1 ms-sm-3">日曜日</dd>
        </dl>
      </Container>
    </BasicPageBase>
  );
};

export default AboutUs;
