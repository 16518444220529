import { FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Badge,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import StepFlow from "../../../molecules/StepFlow";
import { PostCodeResponse } from "../../../../types/PostCodeResponse";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAjax } from "../../../hooks/ajax";
import {
  faAngleUp,
  faAngleDown,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

type MemberInfoForm = {
  password: string;
  purchasingPersonnel: {
    lastName: string;
    firstName: string;
    lastNameKana: string;
    firstNameKana: string;
    gender: string;
    birthYear: string;
    birthMonth: string;
    birthDay: string;
    tel: string;
  };
  corporation: {
    name: string;
    no: string;
    postCode: string;
    prefectures: string;
    address1: string;
    address2: string;
    address3: string;
  };
};

type MemberInfoRequest = {
  password: string;
  purchasingPersonnel: {
    lastName: string;
    firstName: string;
    lastNameKana: string;
    firstNameKana: string;
    gender: string;
    birthday: Date;
    tel: string;
  };
  corporation: {
    name: string;
    no: string;
    postCode: string;
    prefectures: string;
    address1: string;
    address2: string;
    address3: string;
    images: string[];
  };
};

const Register: FC<{ complated: () => void }> = (props) => {
  const ajax = useAjax(false);
  const location = useLocation();
  const [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<MemberInfoForm>({ reValidateMode: "onBlur" });
  const [files, setFiles] = useState<(File & { preview: string })[]>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles((files) => {
        return [
          ...files,
          ...acceptedFiles.map((file) =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
          ),
        ];
      });
    },
  });

  useEffect(() => {
    (async () => {
      const query = parse(location.search) as { token?: string };
      if (!query.token) {
        console.log("error");
        return;
      }
      const result = await ajax.get<{ email: string }>(
        `/api/members/info/${query.token}`
      );
      setEmail(result.data.email);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const removeFile = (index: number) => {
    setFiles((files) => {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
  const upFile = (index: number) => {
    setFiles((files) => {
      const newFiles = [...files];
      newFiles.splice(index - 1, 2, files[index], files[index - 1]);
      return newFiles;
    });
  };
  const downFile = (index: number) => {
    setFiles((files) => {
      const newFiles = [...files];
      newFiles.splice(index, 2, files[index + 1], files[index]);
      return newFiles;
    });
  };
  const thumbs = files.map((file, index) => (
    <div key={index} className="thumb-item">
      <div className="thumb-img">
        <img src={file.preview} alt="" />
      </div>
      <div className="thumb-right">
        <div className="thumb-operation text-end">
          <Button
            type="button"
            variant="outline-secondary me-1"
            onClick={() => upFile(index)}
            disabled={index === 0}
          >
            <FontAwesomeIcon icon={faAngleUp} />
          </Button>
          <Button
            type="button"
            variant="outline-secondary me-1"
            onClick={() => downFile(index)}
            disabled={index === files.length - 1}
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </Button>
          <Button
            type="button"
            variant="outline-danger"
            onClick={() => removeFile(index)}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        </div>
        <div className="thumb-info mt-sm-3">
          <Row>
            <Col sm={4} className="small text-muted text-sm-end">
              ファイル名
            </Col>
            <Col sm={8}>{file.name}</Col>
          </Row>
          <Row>
            <Col sm={4} className="small text-muted text-sm-end">
              サイズ
            </Col>
            <Col sm={8}>{file.size / 1000} KB</Col>
          </Row>
        </div>
      </div>
    </div>
  ));

  const onClickInputAddress = async () => {
    const postCode = getValues("corporation.postCode");
    if (!postCode) {
      return;
    }
    const result = await ajax.get<PostCodeResponse>(
      `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postCode}`
    );
    if (!result.data.results || !result.data.results.length) {
      return;
    }

    if (result.data.results[0].address1) {
      setValue("corporation.prefectures", result.data.results[0].address1, {
        shouldValidate: true,
      });
    }
    if (result.data.results[0].address2) {
      setValue(
        "corporation.address1",
        `${result.data.results[0].address2}${
          result.data.results[0].address3
            ? ` ${result.data.results[0].address3}`
            : ""
        }`,
        { shouldValidate: true }
      );
    }
  };

  const onSubmit = async (data: MemberInfoForm) => {
    const { token } = parse(location.search) as { token?: string };
    console.log(data);

    const images = await Promise.all(
      files.map((file) => {
        return getImage(file);
      })
    );

    const uploadTargets = images.map((image, index) => {
      const resize = getResizeImageSize(image, {
        maxWidth: 1090,
        maxHeight: 1090,
      });
      if (image.width <= 1090 && image.height <= 1090) {
        return files[index];
      }
      return toBlob(getCanvas(image, resize), files[index].type);
    });

    const results = await Promise.all(
      uploadTargets.map((file) => {
        const params = new FormData();
        params.append("image", file);
        return ajax.post<{ message: string; filename: string }>(
          `/api/members/info/${token}/images`,
          params,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
      })
    );

    const requestBody: MemberInfoRequest = {
      password: data.password,
      purchasingPersonnel: {
        lastName: data.purchasingPersonnel.lastName,
        firstName: data.purchasingPersonnel.firstName,
        lastNameKana: data.purchasingPersonnel.lastNameKana,
        firstNameKana: data.purchasingPersonnel.firstNameKana,
        gender: data.purchasingPersonnel.gender,
        birthday: new Date(
          +data.purchasingPersonnel.birthYear,
          +data.purchasingPersonnel.birthMonth - 1,
          +data.purchasingPersonnel.birthDay,
          0,
          0,
          0
        ),
        tel: data.purchasingPersonnel.tel,
      },
      corporation: {
        name: data.corporation.name,
        no: data.corporation.no,
        postCode: data.corporation.postCode,
        prefectures: data.corporation.prefectures,
        address1: data.corporation.address1,
        address2: data.corporation.address2,
        address3: data.corporation.address3,
        images: results.map((result) => result.data.filename),
      },
    };

    await ajax.put(`/api/members/info/${token}`, requestBody);
    props.complated();
  };
  return (
    <Container>
      <h2>会員登録</h2>
      <StepFlow
        labels={["メール登録", "メール確認", "会員情報登録", "確認待ち"]}
        current={3}
      />
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <h3>アカウント情報</h3>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>メールアドレス</Form.Label>
          <Form.Control
            type="text"
            plaintext={true}
            value={email}
            readOnly={true}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>
            パスワード
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <Form.Control
            type="password"
            {...register("password", {
              required: "パスワードを入力してください",
            })}
            isInvalid={!!errors.password}
          />
          <Form.Text muted>8桁以上の英数字を指定してください</Form.Text>
          {errors.password && (
            <Form.Text className="text-danger d-block">
              {errors.password.message}
            </Form.Text>
          )}
        </Form.Group>
        <h3>販売担当者情報</h3>
        <Form.Group className="mb-3" controlId="purchasingPersonnelName">
          <Form.Label>
            担当者名
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <Row>
            <Col sm={6}>
              <Form.Control
                type="text"
                placeholder="例: 田中"
                {...register("purchasingPersonnel.lastName", {
                  required: "姓を入力してください",
                })}
                isInvalid={!!errors.purchasingPersonnel?.lastName}
              />
            </Col>
            <Col sm={6}>
              <Form.Control
                type="text"
                placeholder="例: 花子"
                {...register("purchasingPersonnel.firstName", {
                  required: "名を入力してください",
                })}
                isInvalid={!!errors.purchasingPersonnel?.firstName}
              />
            </Col>
          </Row>
          {errors.purchasingPersonnel?.lastName && (
            <Form.Text className="text-danger d-block">
              {errors.purchasingPersonnel?.lastName.message}
            </Form.Text>
          )}
          {errors.purchasingPersonnel?.firstName && (
            <Form.Text className="text-danger d-block">
              {errors.purchasingPersonnel?.firstName.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="purchasingPersonnelNameKana">
          <Form.Label>
            担当者名(カナ)
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <Row>
            <Col sm={6}>
              <Form.Control
                type="text"
                placeholder="例: タナカ"
                {...register("purchasingPersonnel.lastNameKana", {
                  required: "セイを入力してください",
                })}
                isInvalid={!!errors.purchasingPersonnel?.lastNameKana}
              />
            </Col>
            <Col sm={6}>
              <Form.Control
                type="text"
                placeholder="例: ハナコ"
                {...register("purchasingPersonnel.firstNameKana", {
                  required: "メイを入力してください",
                })}
                isInvalid={!!errors.purchasingPersonnel?.firstNameKana}
              />
            </Col>
          </Row>
          {errors.purchasingPersonnel?.lastNameKana && (
            <Form.Text className="text-danger d-block">
              {errors.purchasingPersonnel?.lastNameKana.message}
            </Form.Text>
          )}
          {errors.purchasingPersonnel?.firstNameKana && (
            <Form.Text className="text-danger d-block">
              {errors.purchasingPersonnel?.firstNameKana.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="purchasingPersonnelGender">
          <Form.Label>
            性別
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <Row>
            <Col sm={3}>
              <Form.Check
                type="radio"
                id={`radio-gender-fale`}
                label="女性"
                value="1"
                {...register("purchasingPersonnel.gender", {
                  required: "性別を選択してください",
                })}
                isInvalid={!!errors.purchasingPersonnel?.gender}
              />
            </Col>
            <Col sm={3}>
              <Form.Check
                type="radio"
                label="男性"
                id={`radio-gender-otoko`}
                value="2"
                {...register("purchasingPersonnel.gender", {
                  required: "性別を選択してください",
                })}
                isInvalid={!!errors.purchasingPersonnel?.gender}
              />
            </Col>
            <Col sm={3}>
              <Form.Check
                type="radio"
                label="その他"
                id={`radio-gender-other`}
                value="3"
                {...register("purchasingPersonnel.gender", {
                  required: "性別を選択してください",
                })}
                isInvalid={!!errors.purchasingPersonnel?.gender}
              />
            </Col>
            <Col sm={3}>
              <Form.Check
                type="radio"
                label="回答しない"
                id={`radio-gender-not-answer`}
                value="4"
                {...register("purchasingPersonnel.gender", {
                  required: "性別を選択してください",
                })}
                isInvalid={!!errors.purchasingPersonnel?.gender}
              />
            </Col>
          </Row>
          {errors.purchasingPersonnel?.gender && (
            <Form.Text className="text-danger d-block">
              {errors.purchasingPersonnel?.gender.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="purchasingPersonnelBirth">
          <Form.Label>
            生年月日
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <Row>
            <Col sm={4}>
              <InputGroup>
                <Form.Control
                  type="tel"
                  placeholder="例: 1990"
                  {...register("purchasingPersonnel.birthYear", {
                    required: "西暦を入力してください",
                  })}
                  isInvalid={!!errors.purchasingPersonnel?.birthYear}
                />
                <InputGroup.Text>年</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col sm={4}>
              <InputGroup>
                <Form.Control
                  type="tel"
                  placeholder="例: 1"
                  {...register("purchasingPersonnel.birthMonth", {
                    required: "月を入力してください",
                  })}
                  isInvalid={!!errors.purchasingPersonnel?.birthMonth}
                />
                <InputGroup.Text>月</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col sm={4}>
              <InputGroup>
                <Form.Control
                  type="tel"
                  placeholder="例: 23"
                  {...register("purchasingPersonnel.birthDay", {
                    required: "日を入力してください",
                  })}
                  isInvalid={!!errors.purchasingPersonnel?.birthDay}
                />
                <InputGroup.Text>日</InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Form.Text muted>年には西暦を指定してください</Form.Text>
          {errors.purchasingPersonnel?.birthYear && (
            <Form.Text className="text-danger d-block">
              {errors.purchasingPersonnel?.birthYear.message}
            </Form.Text>
          )}
          {errors.purchasingPersonnel?.birthMonth && (
            <Form.Text className="text-danger d-block">
              {errors.purchasingPersonnel?.birthMonth.message}
            </Form.Text>
          )}
          {errors.purchasingPersonnel?.birthDay && (
            <Form.Text className="text-danger d-block">
              {errors.purchasingPersonnel?.birthDay.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="purchasingPersonnelTel">
          <Form.Label>
            電話番号
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="tel"
              placeholder="例: 0312345678"
              {...register("purchasingPersonnel.tel", {
                required: "電話番号を入力してください",
              })}
              isInvalid={!!errors.purchasingPersonnel?.tel}
            />
          </Col>
          <Form.Text muted>
            日中に連絡がつく番号を入力してください(携帯番号でも可)
          </Form.Text>
          {errors.purchasingPersonnel?.tel && (
            <Form.Text className="text-danger d-block">
              {errors.purchasingPersonnel?.tel.message}
            </Form.Text>
          )}
        </Form.Group>
        <h3>事業者情報</h3>
        <Form.Group className="mb-3" controlId="corporationNo">
          <Form.Label>
            法人番号
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="tel"
              placeholder="例: 1234567890123"
              {...register("corporation.no", {
                required: "法人番号を入力してください",
              })}
              isInvalid={!!errors.corporation?.no}
            />
          </Col>
          {errors.corporation?.no && (
            <Form.Text className="text-danger d-block">
              {errors.corporation?.no.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="corporationName">
          <Form.Label>
            商号・名称
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="例: 株式会社 販売店"
              {...register("corporation.name", {
                required: "商号・名称を入力してください",
              })}
              isInvalid={!!errors.corporation?.name}
            />
          </Col>
          <Form.Text muted>
            法人番号に紐づく情報を入力してください。全国法人データベースで一致することを確認します
          </Form.Text>
          {errors.corporation?.name && (
            <Form.Text className="text-danger d-block">
              {errors.corporation?.name.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="corporationAddress">
          <Form.Label>
            住所
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <Row>
            <Col sm={6}>
              <Form.Label>郵便番号</Form.Label>
              <InputGroup>
                <InputGroup.Text>〒</InputGroup.Text>
                <Form.Control
                  type="tel"
                  placeholder="例: 1000001"
                  {...register("corporation.postCode", {
                    required: "郵便番号を入力してください",
                  })}
                  isInvalid={!!errors.corporation?.postCode}
                />
              </InputGroup>
              <Form.Text muted>ハイフンなし7桁</Form.Text>
            </Col>
            <Col sm={6}>
              <Form.Label>住所自動入力</Form.Label>
              <div>
                <Button type="button" onClick={onClickInputAddress}>
                  住所自動入力
                </Button>
              </div>
            </Col>
          </Row>
          {errors.corporation?.postCode && (
            <Form.Text className="text-danger d-block">
              {errors.corporation?.postCode.message}
            </Form.Text>
          )}
          <Row>
            <Col sm={6}>
              <Form.Label>都道府県</Form.Label>
              <Form.Control
                type="text"
                placeholder="例: 東京都"
                {...register("corporation.prefectures", {
                  required: "都道府県を入力してください",
                })}
                isInvalid={!!errors.corporation?.prefectures}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>市区町村</Form.Label>
              <Form.Control
                type="text"
                placeholder="例: 千代田区 千代田"
                {...register("corporation.address1", {
                  required: "市区町村を入力してください",
                })}
                isInvalid={!!errors.corporation?.address1}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>番地</Form.Label>
              <Form.Control
                type="text"
                placeholder="例: １丁目 2番 3号"
                {...register("corporation.address2", {
                  required: "番地を入力してください",
                })}
                isInvalid={!!errors.corporation?.address2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>建物名・部屋番号</Form.Label>
              <Form.Control
                type="text"
                placeholder="例: テストビル 101号室"
                {...register("corporation.address3")}
                isInvalid={!!errors.corporation?.address3}
              />
            </Col>
          </Row>
          <Form.Text muted className="d-block">
            法人番号に紐づく情報を入力してください。全国法人データベースで一致することを確認します
          </Form.Text>
          <Form.Text muted className="d-block">
            また、本住所は商品の送付先に利用されます
          </Form.Text>
          {errors.corporation?.prefectures && (
            <Form.Text className="text-danger d-block">
              {errors.corporation?.prefectures.message}
            </Form.Text>
          )}
          {errors.corporation?.address1 && (
            <Form.Text className="text-danger d-block">
              {errors.corporation?.address1.message}
            </Form.Text>
          )}
          {errors.corporation?.address2 && (
            <Form.Text className="text-danger d-block">
              {errors.corporation?.address2.message}
            </Form.Text>
          )}
          {errors.corporation?.address3 && (
            <Form.Text className="text-danger d-block">
              {errors.corporation?.address3.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="corporationImages">
          <Form.Label>画像</Form.Label>
          <div {...getRootProps({ className: "dropzone mb-4" })}>
            <input {...getInputProps()} />
            <p>
              画像をドラッグアンドドロップ
              <br />
              またはクリック(タップ)してファイルを選択してください
            </p>
          </div>
          <aside>{thumbs}</aside>
        </Form.Group>

        <div className="text-end">
          <Button variant="primary" type="submit">
            登録
          </Button>
        </div>
      </Form>
    </Container>
  );
};

const Registerd: FC = (props) => {
  return (
    <Container>
      <h2>会員登録</h2>
      <StepFlow
        labels={["メール登録", "メール確認", "会員情報登録", "確認待ち"]}
        current={3}
        complated={true}
      />
      <p>会員情報の登録が完了しました。</p>
    </Container>
  );
};

const RegisterInfo: FC = () => {
  const [registerd, setRegisterd] = useState(false);
  if (!registerd) {
    return (
      <Register
        complated={() => {
          setRegisterd(true);
        }}
      />
    );
  }
  return <Registerd />;
};

export function getImage(file: File): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.onload = (imageEvent) => {
        resolve(image);
      };
      if (!readerEvent.target) {
        reject("error.");
        return;
      }
      image.src = readerEvent.target.result as string;
    };
    reader.readAsDataURL(file);
  });
}

export function getResizeImageSize(
  image: HTMLImageElement,
  options: { maxWidth: number; maxHeight: number }
) {
  const { maxWidth, maxHeight } = options;
  if (image.width <= maxWidth && image.height <= maxHeight) {
    return {
      width: image.width,
      height: image.height,
    };
  }
  const scalingFactor = Math.min(
    maxWidth / image.width,
    maxHeight / image.height
  );

  return {
    width: image.width * scalingFactor,
    height: image.height * scalingFactor,
  };
}

export function getCanvas(
  image: HTMLImageElement,
  size: { width: number; height: number }
) {
  const { width, height } = size;
  // create a new canvas
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw Error("not initialized canvas.");
  }

  // resize the canvas to the new dimensions
  canvas.width = width;
  canvas.height = height;

  // scale & draw the image onto the canvas
  context.drawImage(image, 0, 0, width, height);

  // return the new canvas with the resized image
  return canvas;
}

function toBinary(canvas: HTMLCanvasElement, type: string) {
  const base64 = canvas.toDataURL(type);
  const bin = window.atob(base64.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  return buffer;
}

export function toBlob(canvas: HTMLCanvasElement, type: string) {
  const buffer = toBinary(canvas, type);
  // Blobを作成
  return new Blob([buffer.buffer], {
    type,
  });
}

export default RegisterInfo;
