import { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import useScrollPosition from "@react-hook/window-scroll";
import Scroll from "react-scroll";
import "./ScrollArrow.scss";

const scroll = Scroll.animateScroll;

const ScrollArrow: FC = () => {
  const [showScroll, setShowScroll] = useState(false);
  const scrollY = useScrollPosition();
  if (!showScroll && scrollY > 400) {
    setShowScroll(true);
  } else if (showScroll && scrollY <= 400) {
    setShowScroll(false);
  }
  const scrollTop = () => {
    scroll.scrollToTop({ duration: 200 });
  };
  return (
    <button
      type="button"
      onClick={scrollTop}
      className={`text-dark scroll-top${showScroll ? " in" : " d-none"}`}
    >
      <FontAwesomeIcon icon={faArrowCircleUp} className="h-100" />
    </button>
  );
};

export default ScrollArrow;
