import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BasicPageBase from "../../templates/BasicPageBase";

const title = "お問い合わせ";
const description = "お問い合わせに関する情報を公開しているページです。";

const ContactUs: FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <BasicPageBase>
      <Helmet>
        <title>{title} | confidence cigar</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Container className="mt-4">
        <h3 className="mb-5">お問い合わせ</h3>
        <h4 className="h5 fw-bold">商取引に関するお問い合わせ</h4>
        <p className="mt-4 ms-3 fs-1 mb-0">
          contact
          <FontAwesomeIcon icon={faStar} />
          confidence-cigar.com
        </p>
        <p className="mb-4 ms-3">株式会社confidence 取引担当</p>
        <ul className="mb-5 options">
          <li>
            実際にメールを送る際は「
            <FontAwesomeIcon icon={faStar} />
            」を「@」に置き換えてください
          </li>
          <li>
            入荷ご要望の葉巻がございましたら、銘柄、必要本数（消費頻度等）を添えてご連絡下さい。
            <br />
            全てにご対応可能ではございませんが、可能な限り入荷できるよう尽力致します。
            <br />
            入荷の際はTwitter、HPにてご連絡致します。
          </li>
          <li>
            お問い合わせの内容により、返答にお時間を頂戴する場合がございます。
            <br />
            商品のご注文に関するお問合わせ以外は返答いたしかねますので予めご了承ください。
          </li>
        </ul>
        <h4 className="h5 fw-bold">その他お問い合わせ</h4>
        <p className="mt-4 ms-3 fs-1 mb-4">
          contact
          <FontAwesomeIcon icon={faStar} />
          confidence-cigar.com
        </p>
        <ul className="mb-5 options">
          <li>
            実際にメールを送る際は「
            <FontAwesomeIcon icon={faStar} />
            」を「@」に置き換えてください
          </li>
          <li>
            保有個人データの開示、訂正に関するお問い合わせの場合、件名を「個人情報の開示・訂正の件」としてお送り下さい
          </li>
          <li>
            個人情報取り扱いに関する相談や苦情の連絡先に関するお問い合わせの場合、件名を「個人情報の取り扱いの件」としてお送り下さい
          </li>
        </ul>
      </Container>
    </BasicPageBase>
  );
};

export default ContactUs;
