import { FC } from "react";
import "./StepFlow.scss";

type StepFlowPropsType = {
  labels: string[];
  current: number;
  complated?: boolean;
};

const StepFlow: FC<StepFlowPropsType> = (props) => {
  let currentIndex = -1;
  let complatedIndex = props.current - 2;
  if (props.complated) {
    complatedIndex = props.current - 1;
  } else {
    currentIndex = props.current - 1;
  }
  const getStatusClassName = (index: number) => {
    if (currentIndex === index) {
      return "active";
    }
    if (complatedIndex >= index) {
      return "completed";
    }
    return "";
  };

  return (
    <ul className="stepflow">
      {props.labels.map((label, index) => {
        return (
          <li key={index} className={getStatusClassName(index)}>
            <span className="bubble"></span>
            <span>{label}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default StepFlow;
