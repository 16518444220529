import { FC } from "react";
import { Navigate } from "react-router-dom";
import { getUser } from "../hooks/ajax";

type Props = {
  redirect?: string;
  roles?: string[];
};
const GuardedRoute: FC<Props> = (props) => {
  const redirect = props.redirect || "/signin";
  const user = getUser();
  if (!user) {
    return <Navigate to={redirect} />;
  }
  if (props.roles) {
    if (
      props.roles.filter((role) => user.roles.indexOf(role) !== -1).length === 0
    ) {
      return <Navigate to={redirect} />;
    }
  }
  return <>{props.children}</>;
};

export default GuardedRoute;
