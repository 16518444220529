import { FC } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminPageBase from "../../templates/AdminPageBase";

const Admin: FC = () => {
  return (
    <AdminPageBase backTo="/" pageTitle="[管理]ダッシュボード">
      <Container>
        <h2>管理者ダッシュボード</h2>
        <div>
          <Link to="/admin/products">商品管理</Link>
        </div>
        <div>
          <Link to="/admin/members">会員管理</Link>
        </div>
      </Container>
    </AdminPageBase>
  );
};

export default Admin;
