import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import BasicPageBase from "../../templates/BasicPageBase";

const Rejected: FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <BasicPageBase mainClassName="text-center">
      <h3 className="mt-5 mb-4">閲覧できません</h3>
      <p>
        20歳未満の方は、
        <br />
        このサイトを閲覧することはできません。
      </p>
      <div className="d-grid gap-2 col-6 col-sm-3 col-lg-2 mx-auto mt-5">
        <Link to="/" className="btn btn-secondary">
          戻る
        </Link>
      </div>
    </BasicPageBase>
  );
};

export default Rejected;
