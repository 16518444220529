import { FC, useEffect } from "react";

const MeasurementIdSt = "G-FNFQDJM40F";
const MeasurementIdPr = "G-GRZ6M3BZR7";
const HostnamePr = "confidence-cigar.com";
// const ignorePathname = "/admin";

let currentMeasurementId = MeasurementIdPr;

function initializeGA(measurementId: string) {
  // load gtag.js:  https://developers.google.com/analytics/devguides/collection/gtagjs
  let script1 = document.createElement("script");
  script1.src = "https://www.googletagmanager.com/gtag/js?id=" + measurementId;
  script1.async = true;
  document.body.appendChild(script1);

  let script2 = document.createElement("script");
  // プレーンテキスト、グローバルな window.gtag 関数を定義するため
  script2.text = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${measurementId}');`;
  document.body.appendChild(script2);
}

// let currentLocation: Location | undefined;

// const useOnLocationChange = (
//   handleLocationChange: (location: Location) => Promise<void>
// ) => {
//   const location = useLocation();

//   useEffect(() => {
//     (async () => {
//       if (
//         currentLocation &&
//         currentLocation.pathname === location.pathname &&
//         currentLocation.hash === location.hash &&
//         currentLocation.search === location.search
//       ) {
//         return;
//       }
//       currentLocation = location;
//       await handleLocationChange(location);
//     })().catch((err) => {
//       console.error(err);
//     });
//   }, [location, handleLocationChange]);
// };

const Analytics: FC = () => {
  useEffect(() => {
    if (window.location.hostname !== HostnamePr) {
      currentMeasurementId = MeasurementIdSt;
    }
    initializeGA(currentMeasurementId);
  }, []);
  // useOnLocationChange(async (location) => {
  //   if (!window.gtag) {
  //     return;
  //   }
  //   console.log(location.pathname);
  //   if (
  //     location.pathname === ignorePathname ||
  //     location.pathname.startsWith(`${ignorePathname}/`)
  //   ) {
  //     console.log("むし");
  //     return;
  //   }
  //   console.log(currentMeasurementId);
  //   console.log(location.pathname);
  //   console.log(location.pathname + location.hash + location.search);
  //   // window.gtag("set", "send_page_view", true);
  //   // ↑入れると２回計測されてしまう・・・
  // });

  return null;
};

export default Analytics;
