import { FC, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import RegisterEmail from "./components/pages/member/register/RegisterEmail";
import RegisterInfo from "./components/pages/member/register/RegisterInfo";
import VerifyEmail from "./components/pages/member/register/VerifyEmail";
import GuardedRoute from "./components/molecules/GuardedRoute";
import Detail from "./components/pages/admin/members/Detail";
import List from "./components/pages/admin/members/List";
import Register from "./components/pages/admin/products/Register";
import Admin from "./components/pages/admin/Admin";
import Signin from "./components/pages/signin/Signin";
import Products from "./components/pages/products/Products";
import ProductDetail from "./components/pages/products/ProductDetail";
import AdminProductsList from "./components/pages/admin/products/List";
import AdminProductsDetail, {
  Toaster,
} from "./components/pages/admin/products/Detail";
import TwentyAndOverGuardedRoute from "./components/molecules/TwentyAndOverGuardedRoute";
import Rejected from "./components/pages/rejected/Rejected";
import SpecifiedCommercialTransactionsLaw from "./components/pages/specified-commercial-transactions-law/SpecifiedCommercialTransactionsLaw";
import FlowToOrder from "./components/pages/flow-to-order/FlowToOrder";
import PrivacyPolicy from "./components/pages/privacy-policy/PrivacyPolicy";
import TermsOfService from "./components/pages/terms-of-sercie/TermsOfService";
import AboutUs from "./components/pages/about-us/AboutUs";
import ContactUs from "./components/pages/contact-us/ContactUs";
import Reset from "./components/pages/reset/Reset";
import Cart from "./components/pages/cart/Cart";
import LoadingOverlay from "./components/molecules/LoadingOverlay";
import Analytics from "./components/molecules/Analytics";
import "./App.scss";
import Quotation from "./components/pages/quotation/Quotation";

const App: FC = () => {
  useEffect(() => {
    window.addEventListener("load", async (_e) => {
      const version = localStorage.getItem("version");
      const res = await axios.get<{ version: string }>("/version.json");
      if (res.data.version === version) {
        return;
      }
      localStorage.setItem("version", res.data.version);
      window.location.reload();
    });
  });
  return (
    <LoadingOverlay>
      <Toaster>
        {/* <React.StrictMode> */}
        <BrowserRouter>
          <Routes>
            <Route path="/member/register" element={<RegisterEmail />} />
            <Route path="/member/register/verify" element={<VerifyEmail />} />
            <Route path="/member/register/info" element={<RegisterInfo />} />
            <Route
              path="/admin/members/:id"
              element={
                <GuardedRoute roles={["operator"]}>
                  <Detail />
                </GuardedRoute>
              }
            />
            <Route
              path="/admin/members"
              element={
                <GuardedRoute roles={["operator"]}>
                  <List />
                </GuardedRoute>
              }
            />
            <Route
              path="/admin/products/register"
              element={
                <GuardedRoute roles={["operator"]}>
                  <Register />
                </GuardedRoute>
              }
            />
            <Route
              path="/admin/products/:id"
              element={
                <GuardedRoute roles={["operator"]}>
                  <AdminProductsDetail />
                </GuardedRoute>
              }
            />
            <Route
              path="/admin/products"
              element={
                <GuardedRoute roles={["operator"]}>
                  <AdminProductsList />
                </GuardedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <GuardedRoute roles={["operator"]}>
                  <Admin />
                </GuardedRoute>
              }
            />
            <Route path="/signin" element={<Signin />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/quotation" element={<Quotation />} />
            <Route path="/flow-to-order" element={<FlowToOrder />} />
            <Route
              path="/specified-commercial-transactions-law"
              element={<SpecifiedCommercialTransactionsLaw />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/rejected" element={<Rejected />} />
            <Route
              path="/products/:id"
              element={
                <TwentyAndOverGuardedRoute>
                  <ProductDetail />
                </TwentyAndOverGuardedRoute>
              }
            />
            <Route
              path="/"
              element={
                <TwentyAndOverGuardedRoute>
                  <Products />
                </TwentyAndOverGuardedRoute>
              }
            />
          </Routes>
          <Analytics />
        </BrowserRouter>
        {/* </React.StrictMode> */}
      </Toaster>
    </LoadingOverlay>
  );
};

export default App;
