import useMouse from "@react-hook/mouse-position";
import { run as runHolder } from "holderjs";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Badge, Breadcrumb, Container, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { useAjax } from "../../hooks/ajax";
import BasicPageBase from "../../templates/BasicPageBase";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import "./ProductDetail.scss";

type Product = {
  id: number;
  name: string;
  supplementaryInformation?: string;
  price: number;
  priceTax: number;
  edition?: string;
  specialPrice?: number;
  specialPriceTax?: number;
  catchPhrase?: string;
  stockQuantity: number;
  stockQuantityUnit?: string;
  inStockSoon?: boolean;
  onlyFewLeft?: boolean;
  description?: string;
  productImages: { filename: string }[];
  specShape?: string;
  specSize?: string;
  specStrength?: string;
  specTime?: string;
  featuredImageFileName?: string;
};

const ImageZoomViewer: FC<{
  src: string;
  alt?: string;
  zoom: number;
}> = (props) => {
  const target = useRef<HTMLDivElement>(null);
  const mouse = useMouse(target, {
    enterDelay: 100,
    leaveDelay: 100,
  });
  let tranX: number | undefined = undefined;
  let tranY: number | undefined = undefined;
  if (mouse.x && mouse.elementWidth && mouse.y && mouse.elementHeight) {
    tranX = (mouse.x / mouse.elementWidth - 0.5) * (props.zoom - 1) * -100;
    tranY = (mouse.y / mouse.elementHeight - 0.5) * (props.zoom - 1) * -100;
  }
  const isHolderImg = props.src.startsWith("holder.js");

  useEffect(() => {
    const myImage = document.getElementsByClassName(
      "holder-img"
    ) as unknown as HTMLElement;
    runHolder({ images: myImage });
  }, [props.src]);

  return (
    <>
      <MobileView>
        <img
          src={props.src}
          alt={props.alt}
          className={`img-fluid rounded h-auto${
            isHolderImg ? " holder-img" : ""
          }`}
        />
      </MobileView>
      <BrowserView>
        <div className="main-img-frame rounded" ref={target}>
          <img
            src={props.src}
            alt={props.alt}
            className={`invisible img-fluid h-auto w-100${
              isHolderImg ? " holder-img" : ""
            }`}
          />
          <img
            src={props.src}
            alt={props.alt}
            className={`main-img${isHolderImg ? " holder-img" : ""}`}
            style={{
              transform:
                tranX && tranY
                  ? `translate(${tranX}%, ${tranY}%) scale(${props.zoom})`
                  : "",
            }}
          />
        </div>
      </BrowserView>
    </>
  );
};

export function getCarts() {
  const cartStr = localStorage.getItem("cart");
  if (!cartStr) {
    return {};
  }
  const cart = JSON.parse(cartStr) as {
    [id: number]: { num: number };
  };
  return cart;
}

export function getCart(id: number) {
  const cart = getCarts();
  return cart[id] || { num: 0 };
}

export function setCart(id: number, data: { num: number }) {
  const cartStr = localStorage.getItem("cart");
  const cart: { [id: number]: { num: number } } = cartStr
    ? JSON.parse(cartStr)
    : {};
  if (data.num === 0) {
    delete cart[id];
  } else {
    cart[id] = data;
  }
  localStorage.setItem("cart", JSON.stringify(cart));
}

export function removeCart() {
  localStorage.removeItem("cart");
}

const Products: FC = () => {
  const [product, setProduct] = useState<Product | undefined>();
  const [selectedImage, setSelectedImage] = useState(0);
  // const [selectedNum, setSelectedNum] = useState(1);
  // const [numberInTheCart, setNumberInTheCart] = useState(0);
  const ajax = useAjax(false);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const ret = await ajax.get<Product>(`/api/anyone/products/${id}`);
      setProduct(ret.data);
      // setNumberInTheCart(getCart(ret.data.id).num);
      const myImage = document.getElementsByClassName(
        "holder-img"
      ) as unknown as HTMLElement;
      runHolder({ images: myImage });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const description = () => {
    if (!product?.description) {
      return null;
    }
    const paragraphs = product.description
      .trim()
      .split("\n\n")
      .map((p) => p.split("\n"));
    return paragraphs.map((p, pIdx) => (
      <p key={pIdx}>
        {p.map((pText, pTextIdx) => (
          <Fragment key={pTextIdx}>
            {pTextIdx !== 0 && <br />}
            {pText}
          </Fragment>
        ))}
      </p>
    ));
  };

  const label = (product: Product) => {
    const {
      stockQuantity,
      inStockSoon,
      specialPrice: salePrice,
      onlyFewLeft,
    } = product;
    if (stockQuantity === 0) {
      if (inStockSoon) {
        return (
          <Badge bg="gray">
            売り切れ<span>(入荷中)</span>
          </Badge>
        );
      }
      return (
        <Badge bg="gray">
          売り切れ<span>(入荷未定)</span>
        </Badge>
      );
    }
    if (onlyFewLeft) {
      return <Badge bg="yellow">残りわずか</Badge>;
    }
    if (salePrice) {
      return <Badge bg="red">セール</Badge>;
    }
    return null;
  };

  if (!product) {
    return null;
  }

  const getDescription = () => {
    let description = `${product.name} の商品紹介ページです。`;
    if (!product.description) {
      return description;
    }
    description = product.description.replace(/\n/g, "").trim();
    const max = 100;
    if (description.length > max) {
      description = description.slice(0, max - 3) + "...";
    }
    return description;
  };

  const getSpecStrength = () => {
    if (!product.specStrength) {
      return null;
    }
    let level: boolean[] = [];
    switch (product.specStrength) {
      case "Mild":
        level = [true, false, false, false, false];
        break;
      case "Mild Medium":
        level = [true, true, false, false, false];
        break;
      case "Medium":
        level = [true, true, true, false, false];
        break;
      case "Medium Full":
        level = [true, true, true, true, false];
        break;
      case "Full":
        level = [true, true, true, true, true];
        break;
      default:
        return null;
    }
    return level.map((val, idx) => (
      <span key={idx} className={`circle${val ? "" : " circle-outline"}`} />
    ));
  };

  // const getOptions = () => {
  //   const obj = [];
  //   for (let idx = 1; idx <= product.stockQuantity - numberInTheCart; idx++) {
  //     obj.push(
  //       <option key={idx} value={idx.toString()}>
  //         {idx}
  //       </option>
  //     );
  //   }
  //   return obj;
  // };

  // const cart = () => {
  //   setNumberInTheCart(selectedNum + numberInTheCart);
  //   setCart(product.id, { num: selectedNum + numberInTheCart });
  //   setSelectedNum(1);
  // };

  // const getRealProductPriceAndTax = () => {
  //   if (product.specialPrice && product.specialPriceTax) {
  //     return {
  //       price: product.specialPrice,
  //       tax: product.specialPriceTax,
  //     };
  //   }
  //   return {
  //     price: product.price,
  //     tax: product.priceTax,
  //   };
  // };

  return (
    <>
      <BasicPageBase mainClassName="product">
        <Helmet>
          <title>{product.name} | confidence cigar</title>
          <meta name="description" content={getDescription()} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={product.name} />
          <meta property="og:description" content={getDescription()} />
          <meta
            property="og:image"
            content={`${window.location.protocol}//${window.location.host}/api/anyone/products/images/${product.featuredImageFileName}`}
          />
        </Helmet>
        <Container className="mt-2">
          <Breadcrumb>
            <Breadcrumb.Item active>
              <Link
                to="/"
                className="link-secondary text-decoration-none fw-bold"
              >
                商品一覧
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>商品詳細</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <div className="clearfix">
              <div className="d-flex flex-column-reverse flex-sm-row pict-box col-md-7 col-lg-6 col-xl-5 float-md-start me-md-4 mb-4">
                <div className="thumbnail-area mt-2 mt-sm-0 me-0 me-sm-2">
                  <div className="d-flex flow-row flex-sm-column">
                    {product.productImages.map((image, idx) => (
                      <div
                        key={idx}
                        className={`thumbnail-box${
                          selectedImage === idx ? " active" : ""
                        }`}
                        onClick={() => {
                          setSelectedImage(idx);
                        }}
                      >
                        <img
                          src={`/api/anyone/products/images/${image.filename}_350x350`}
                          className="img-thumbnail"
                          alt=""
                        />
                      </div>
                    ))}
                    {product.productImages.length === 0 && (
                      <div className={`thumbnail-box active`}>
                        <img
                          src={`/no_image.svg`}
                          className="img-thumbnail"
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>
                {product.productImages.length !== 0 ? (
                  <div className="main-img-area">
                    <ImageZoomViewer
                      src={`/api/anyone/products/images/${product.productImages[selectedImage].filename}`}
                      zoom={3}
                    />
                  </div>
                ) : (
                  <div className="main-img-area">
                    <ImageZoomViewer src="/no_image.svg" zoom={3} />
                  </div>
                )}
              </div>
              {label(product)}
              {product.edition && (
                <div className="edition">{product.edition}</div>
              )}
              <h3 className="name">{product.name}</h3>
              {product.supplementaryInformation && (
                <div className="text-muted">
                  {product.supplementaryInformation}
                </div>
              )}
              <div
                className={`price${
                  product.specialPrice ? " text-decoration-line-through" : ""
                }`}
              >
                国内定価:{" "}
                {Math.floor(
                  product.price * (1 + product.priceTax / 100)
                ).toLocaleString()}{" "}
                円
              </div>
              {product.specialPrice !== undefined &&
                product.specialPriceTax !== undefined && (
                  <div className="special-price">
                    特価:{" "}
                    {Math.floor(
                      product.specialPrice * (1 + product.specialPriceTax / 100)
                    ).toLocaleString()}{" "}
                    円
                  </div>
                )}
              <div>お見積り価格: お問い合わせください</div>
              {product.catchPhrase && (
                <div className="catch-phrase">{product.catchPhrase}</div>
              )}
              <div className="stock-quantity text-muted">
                残り {product.stockQuantity.toLocaleString()}{" "}
                {product.stockQuantityUnit || "個"}
              </div>
              {/* {numberInTheCart !== 0 && (
                <div className="mt-3">
                  <div className="">
                    <div className="row border rounded ms-0 me-0 pt-1 pb-1">
                      <div className="col-7 pe-0">
                        <div className="row">
                          <div className="col-sm-6 col-md-12 col-lg-6 d-flex align-items-center pe-0">
                            <div className="stock-quantity text-muted mt-0">
                              カート内個数 {numberInTheCart}
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-12 col-lg-6 ps-sm-0 ps-md-default ps-lg-0 pe-0">
                            <div className="stock-quantity text-muted mt-0">
                              小計{" "}
                              {Math.floor(
                                getRealProductPriceAndTax().price *
                                  numberInTheCart *
                                  (1 + getRealProductPriceAndTax().tax / 100)
                              ).toLocaleString()}{" "}
                              円
                            </div>
                            <div className="stock-quantity text-muted mt-0">
                              (税抜{" "}
                              {Math.floor(
                                getRealProductPriceAndTax().price *
                                  numberInTheCart
                              ).toLocaleString()}{" "}
                              円)
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-5 ps-0 d-flex justify-content-end align-items-center">
                        <div>
                          <Button
                            variant="outline-secondary"
                            className="rounded-circle"
                            size="sm"
                            disabled={numberInTheCart <= 1}
                            onClick={() => {
                              setNumberInTheCart(numberInTheCart - 1);
                              setCart(product.id, { num: numberInTheCart - 1 });
                            }}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </Button>
                          <Button
                            variant="outline-secondary"
                            className="rounded-circle"
                            size="sm"
                            disabled={numberInTheCart >= product.stockQuantity}
                            onClick={() => {
                              setNumberInTheCart(numberInTheCart + 1);
                              setCart(product.id, { num: numberInTheCart + 1 });
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          <Button
                            variant="outline-secondary"
                            className="rounded-circle"
                            size="sm"
                            style={{ width: "30.25px" }}
                            onClick={() => {
                              setNumberInTheCart(0);
                              setCart(product.id, { num: 0 });
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <Link
                      type="button"
                      to="/quotation"
                      className="btn btn-warning btn-sm-lsz btn-md-nsz btn-lg-lsz"
                    >
                      お見積に進む
                    </Link>
                    <Link
                      to="/cart"
                      className="btn btn-outline-secondary ms-2 btn-sm-lsz btn-md-nsz btn-lg-lsz"
                    >
                      カートに移動
                    </Link>
                  </div>
                </div>
              )}
              {product.stockQuantity !== 0 && numberInTheCart === 0 && (
                <div className="mt-3">
                  <div className="row mb-3">
                    <label className="col-3 col-form-label">数量</label>
                    <div className="col-9">
                      <select
                        className="form-select w-auto"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setSelectedNum(+e.target.value);
                        }}
                        value={selectedNum}
                      >
                        {getOptions()}
                      </select>
                    </div>
                  </div>

                  <Button
                    variant="warning"
                    type="button"
                    size="lg"
                    onClick={() => cart()}
                  >
                    カートに入れる
                  </Button>
                </div>
              )} */}
              {description() && (
                <div className="description">{description()}</div>
              )}
            </div>
            <dl className="spec">
              {product.specShape && (
                <>
                  <dt>形状</dt>
                  <dd>{product.specShape}</dd>
                </>
              )}
              {product.specSize && (
                <>
                  <dt>サイズ</dt>
                  <dd>{product.specSize}</dd>
                </>
              )}
              {product.specStrength && (
                <>
                  <dt>強さ</dt>
                  <dd className="circle-level">{getSpecStrength()}</dd>
                </>
              )}
              {product.specTime && (
                <>
                  <dt>喫煙時間</dt>
                  <dd>{product.specTime}</dd>
                </>
              )}
            </dl>
          </Row>
        </Container>
      </BasicPageBase>
    </>
  );
};
export default Products;
