import queryString from "query-string";
import { FC, useState } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setUser, useAjax, User } from "../../hooks/ajax";
import "./Signin.scss";

const baseTitle = "confidence cigar";

const Signin: FC = () => {
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; password: string }>();
  const ajax = useAjax(false);
  const navigate = useNavigate();
  const location = useLocation();
  const search = queryString.parse(location.search) as { redirect: string };

  const onSubmit = async (data: { email: string; password: string }) => {
    setError(false);
    try {
      const form = new URLSearchParams();
      form.append("grant_type", "password");
      form.append("username", data.email);
      form.append("password", data.password);
      const res = await ajax.post<User>("/auth/token", form);
      setUser(res.data);
      if (search.redirect) {
        navigate(search.redirect);
        return;
      }
      if (res.data.roles.indexOf("operator") !== -1) {
        navigate("/admin");
      }
    } catch (err) {
      setError(true);
    }
  };
  return (
    <div className="signin">
      <Helmet>
        <title>サインイン | {baseTitle}</title>
      </Helmet>
      <div className="form-wrappper">
        <Card>
          <Card.Header>
            <h3>サインイン</h3>
          </Card.Header>
          <Card.Body>
            {error && (
              <Alert variant="danger">
                パスワードを再入力してください。
                <br />
                <small>
                  (Caps Lockがオンになっていないことを確認してください)
                </small>
              </Alert>
            )}
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>メールアドレス</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="例: please@entry.mail"
                  {...register("email", {
                    required: "メールアドレスを指定してください",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "メールアドレスを入力してください",
                    },
                  })}
                  isInvalid={!!errors.email}
                />
                {errors.email && (
                  <Form.Text className="text-danger">
                    {errors.email.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>パスワード</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="例: passw@rd"
                  {...register("password", {
                    required: "パスワードを指定してください",
                  })}
                  isInvalid={!!errors.password}
                />
                {errors.password && (
                  <Form.Text className="text-danger">
                    {errors.password.message}
                  </Form.Text>
                )}
              </Form.Group>
              <div className="text-end">
                <Button variant="primary" type="submit">
                  ログイン
                </Button>
              </div>
            </Form>
          </Card.Body>
          <Card.Footer>
            <div className="text-center">
              アカウントを持っていない場合は
              <br className="d-block d-sm-none" />
              <a href="#" className="ms-sm-1">
                サインアップ
              </a>
            </div>
            <div className="text-center">
              パスワードを忘れた場合は
              <br className="d-block d-sm-none" />
              <Link to="/reset" className="ms-sm-1">
                パスワードリセット
              </Link>
            </div>
            <div className="text-center mt-2">
              <Link to="/">トップへ戻る</Link>
            </div>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

export default Signin;
