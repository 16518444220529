import { parse } from "query-string";
import { FC, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useAjax } from "../../../hooks/ajax";
import StepFlow from "../../../molecules/StepFlow";

const Verify: FC<{ complated: (token: string) => void }> = (props) => {
  const ajax = useAjax(false);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const query = parse(location.search) as { token?: string };
      if (!query.token) {
        return;
      }
      const result = await ajax.put<{ message: string; newToken: string }>(
        `/api/members/email/${query.token}`
      );
      props.complated(result.data.newToken);
    })();
  }, [location.search, props, ajax]);
  return (
    <Container>
      <h2>会員登録</h2>
      <StepFlow
        labels={["メール登録", "メール確認", "会員情報登録", "確認待ち"]}
        current={2}
      />
      <p>登録されたメールアドレスを確認します。</p>
    </Container>
  );
};

const Verified: FC<{ token: string }> = (props) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate({
      pathname: "/member/register/info",
      search: `?token=${props.token}`,
    });
  };
  return (
    <Container>
      <h2>会員登録</h2>
      <StepFlow
        labels={["メール登録", "メール確認", "会員情報登録", "確認待ち"]}
        current={2}
        complated={true}
      />
      <p>
        メールアドレスの所有を確認しました。
        <br />
        続けて会員情報を登録してください。
      </p>
      <Button type="button" onClick={onClick}>
        会員情報の登録へ
      </Button>
    </Container>
  );
};

const VerifyEmail: FC = () => {
  const [token, setToken] = useState("");
  const onComplated = (token: string) => {
    setToken(token);
  };

  if (!token) {
    return <Verify complated={onComplated} />;
  } else {
    return <Verified token={token} />;
  }
};

export default VerifyEmail;
