import { createContext, FC, useState } from "react";
import ReactLoadingOverlay from "react-loading-overlay-ts";

export const LoadingOverlayContext = createContext<{
  block?: () => void;
  unblock?: (force?: boolean) => void;
}>({});

const LoadingOverlay: FC = (props) => {
  const [isActive, setActive] = useState(false);
  const [count, setCount] = useState(0);
  const block = () => {
    if (count === 0) {
      setActive(true);
    }
    setCount((count) => count++);
  };

  const unblock = (force = false) => {
    if (force) {
      setCount(0);
      setActive(false);
      return;
    }
    if (count > 0) {
      setCount((count) => count--);
    }
    if (count === 0) {
      setActive(false);
    }
  };

  return (
    <ReactLoadingOverlay active={isActive} spinner>
      <LoadingOverlayContext.Provider value={{ block, unblock }}>
        {props.children}
      </LoadingOverlayContext.Provider>
    </ReactLoadingOverlay>
  );
};

export default LoadingOverlay;
