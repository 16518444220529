import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import {
  faCartShopping,
  faEnvelope,
  faGaugeHigh,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getUser } from "../hooks/ajax";
import ScrollArrow from "../molecules/ScrollArrow";
import "./BasicPageBase.scss";

const title = "confidence cigar";

const Header: FC = () => {
  const user = getUser();
  return (
    <div className="header">
      <Container>
        <Row className="align-items-center">
          <Col md={4} className="d-none d-md-block"></Col>
          <Col md={4}>
            <h1 className="logo text-center">
              <Link to="/">
                <img src="/logo.svg" alt="ロゴ" />
                <span className="d-none">サイト名</span>
              </Link>
            </h1>
          </Col>
          <Col md={4} className="mb-2 mb-md-0">
            <div className="d-flex justify-content-end menu">
              <div>
                <Link
                  to="/flow-to-order"
                  className="link-dark text-decoration-none fs-4"
                >
                  <FontAwesomeIcon icon={faCartShopping} />
                </Link>
              </div>
              <div className="user fs-4">
                {user &&
                  (user.roles.indexOf("operator") !== -1 ||
                    user.roles.indexOf("root") !== -1) && (
                    <Link
                      to="/admin"
                      className="link-secondary text-decoration-none"
                    >
                      <span className="d-none d-md-inline-block me-1 fs-6">
                        管理者画面
                      </span>
                      <FontAwesomeIcon
                        icon={faGaugeHigh}
                        className="d-md-none"
                      />
                    </Link>
                  )}
                {/* {!user && (
                  <>
                    <Link
                      to="/member/register"
                      className="link-secondary text-decoration-none"
                    >
                      <span className="d-none d-md-inline-block me-1 fs-6">
                        会員登録
                      </span>
                      <FontAwesomeIcon icon={faUserPen} className="d-md-none" />
                    </Link>
                    <Link
                      to="/signin"
                      className="link-secondary text-decoration-none"
                    >
                      <span className="d-none d-md-inline-block me-1 fs-6">
                        ログイン
                      </span>
                      <FontAwesomeIcon
                        icon={faRightToBracket}
                        className="d-md-none"
                      />
                    </Link>
                  </>
                )} */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Footer: FC = () => {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col sm={4} className="text-center text-sm-start mb-3 mb-sm-0">
            <h5>会社情報</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  to="/about-us"
                  className="link-secondary text-decoration-none small"
                >
                  当社について
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  className="link-secondary text-decoration-none small"
                >
                  お問い合わせ
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={4} className="text-center text-sm-start mb-3 mb-sm-0">
            <h5>情報</h5>
            <ul className="list-unstyled mb-0">
              <li>
                <Link
                  to="/flow-to-order"
                  className="link-secondary text-decoration-none small"
                >
                  ご注文の流れ
                </Link>
              </li>
              <li>
                <Link
                  to="/specified-commercial-transactions-law"
                  className="link-secondary text-decoration-none small"
                >
                  特定商取引法に基づく表示
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="link-secondary text-decoration-none small"
                >
                  プライバシーポリシー
                </Link>
              </li>
              <li>
                <Link
                  to="/terms-of-service"
                  className="link-secondary text-decoration-none small"
                >
                  利用規約
                </Link>
              </li>
              <li>
                <Link
                  to="/signin"
                  className="link-secondary text-decoration-none small signin-link"
                >
                  ログイン
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={4}>
            <div className="d-flex social justify-content-center justify-content-sm-start mb-2">
              <div>
                <a
                  href="https://twitter.com/ConfidenceCigar"
                  className="text-dark"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </div>
              {/* <div>
                <FontAwesomeIcon icon={faInstagram} />
              </div> */}
              <div>
                <a href="tel:08062871024" className="text-dark">
                  <FontAwesomeIcon icon={faPhone} />
                </a>
              </div>
              <div>
                <a
                  href="mailto:contact@confidence-cigar.com"
                  className="text-dark"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <div className="text-center text-sm-end text-muted">
          © 2022, confidence
        </div>
      </Container>
    </div>
  );
};

const BasicPageBase: FC<{ mainClassName?: string }> = (props) => {
  const description =
    "飲食店様、小売店様向け、葉巻の卸売販売サイトです。キューバ産をはじめ、ニカラグアやドミニカ産の葉巻も取り扱っております。";
  return (
    <div className="basic-page">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={title} />
        <meta
          property="og:image"
          content={`${window.location.protocol}//${window.location.host}/ogp.png`}
        />
      </Helmet>
      <Header />
      <div
        className={`main-container${
          props.mainClassName ? ` ${props.mainClassName}` : ""
        }`}
      >
        {props.children}
      </div>
      <Footer />
      <ScrollArrow />
    </div>
  );
};

export default BasicPageBase;
