import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./AdminPageBase.scss";

const Version = process.env.REACT_APP_VERSION;

const baseTitle = "confidence cigar";

const Header: FC<{ backTo?: string }> = (props) => {
  return (
    <div className="header">
      <Navbar bg="light" expand="lg">
        <Container>
          <div>
            {props.backTo && (
              <Link to={props.backTo} className="back">
                <FontAwesomeIcon icon={faAngleLeft} />
              </Link>
            )}
            <Link to="/admin" className="navbar-brand">
              管理者画面
            </Link>
          </div>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link to="/admin/products" className="nav-link">
                商品管理
              </Link>
              <Link to="/admin/members" className="nav-link">
                会員管理
              </Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.ItemText className="small text-muted">
                  v{Version}
                </NavDropdown.ItemText>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

const Footer: FC = () => {
  return (
    <div className="footer">
      <Container>
        <div className="text-center text-muted">© 2022, confidence</div>
      </Container>
    </div>
  );
};

const AdminPageBase: FC<{
  mainClassName?: string;
  backTo?: string;
  pageTitle?: string;
}> = (props) => {
  useEffect(() => {
    document.title = `${
      props.pageTitle ? props.pageTitle + " | " : ""
    }${baseTitle}`;
  }, [props.pageTitle]);
  return (
    <div className="admin-page">
      <Header backTo={props.backTo} />
      <div
        className={`main-container${
          props.mainClassName ? ` ${props.mainClassName}` : ""
        }`}
      >
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default AdminPageBase;
