import axios from "axios";
import { useContext } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { LoadingOverlayContext } from "../molecules/LoadingOverlay";

export type User = {
  token: string;
  tokenExpiresIn: number;
  refreshToken: string;
  refreshTokenExpiresIn: number;
  roles: string[];
};

type UserLocal = User & {
  tokenExp: number;
  refreshTokenExp: number;
};

let userLocal = getUserFromLocalStorage();

export function setUser(user: User | undefined) {
  if (!user) {
    userLocal = undefined;
    return;
  }
  const epochSec = Math.floor(new Date().getTime() / 1000);
  userLocal = {
    ...user,
    tokenExp: epochSec + user.tokenExpiresIn,
    refreshTokenExp: epochSec + user.refreshTokenExpiresIn,
  };
  setUserToLocalStorage(userLocal);
}

export function getUser() {
  return userLocal;
}

export function deleteUser() {
  localStorage.removeItem("user");
  userLocal = undefined;
}

export function useAjax(authIntercepter = true) {
  const consumer = useContext(LoadingOverlayContext);
  const navigate = useNavigate();
  return createAjaxInstance({ navigate, authIntercepter, consumer });
}

export async function getToken() {
  if (!userLocal) {
    return undefined;
  }
  const now = Math.floor(new Date().getTime() / 1000);
  if (userLocal.tokenExp - 300 <= now) {
    if (userLocal.refreshTokenExp - 300 <= now) {
      return undefined;
    }
    try {
      const resUser = await getTokenFromRefreshToken();
      setUser(resUser);
      return resUser.token;
    } catch {
      return undefined;
    }
  }
  return userLocal.token;
}

function createAjaxInstance(props: {
  navigate: NavigateFunction;
  authIntercepter: boolean;
  consumer: {
    block?: (() => void) | undefined;
    unblock?: ((force?: boolean | undefined) => void) | undefined;
  };
}) {
  const {
    authIntercepter,
    consumer: { block, unblock },
    navigate,
  } = props;

  const inst = axios.create();
  inst.interceptors.request.use(async (config) => {
    block!();
    if (!authIntercepter) {
      return config;
    }

    if (!config.url?.startsWith("/api")) {
      return config;
    }
    if (!userLocal) {
      navigate("/signin");
      throw new axios.Cancel(
        "トークンがないためリクエストはキャンセルされました"
      );
    }
    const token = await getToken();
    if (!token) {
      navigate("/signin");
      throw new axios.Cancel(
        "トークンがないためリクエストはキャンセルされました"
      );
    }
    config.headers = { Authorization: `Bearer ${token}` };
    return config;
  });
  inst.interceptors.response.use(
    async (value) => {
      unblock!();
      return value;
    },
    async (error) => {
      unblock!(true);
      throw error;
    }
  );
  return inst;
}

let retrievingTokenCallback: {
  resolve: (value: User | PromiseLike<User>) => void;
  reject: (reason?: any) => void;
}[] = [];
let retrievingToken = false;

async function getTokenFromRefreshToken() {
  if (!userLocal || !userLocal.refreshToken) {
    throw Error("not found refresh token.");
  }
  if (retrievingToken) {
    return new Promise<User>((resolve, reject) => {
      retrievingTokenCallback.push({ resolve, reject });
    });
  }
  retrievingToken = true;
  const form = new URLSearchParams();
  form.append("grant_type", "refresh_token");
  form.append("refresh_token", userLocal.refreshToken);
  try {
    const res = await axios.post<User>("/auth/token", form);
    retrievingTokenCallback.forEach(({ resolve }) => resolve(res.data));
    return res.data;
  } catch (err) {
    retrievingTokenCallback.forEach(({ reject }) => reject(err));
    throw err;
  } finally {
    retrievingTokenCallback = [];
    retrievingToken = false;
  }
}

function setUserToLocalStorage(userLocal: UserLocal | undefined) {
  if (!userLocal) {
    localStorage.removeItem("user");
    return;
  }
  localStorage.setItem("user", JSON.stringify(userLocal));
}

function getUserFromLocalStorage() {
  const userStr = localStorage.getItem("user");
  if (!userStr) {
    return undefined;
  }
  return JSON.parse(userStr) as UserLocal;
}
