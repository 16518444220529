import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { Alert, Button, Card, Form, InputGroup } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAjax } from "../../hooks/ajax";
import "./Reset.scss";

const baseTitle = "confidence cigar";

type ResetRequestForm = {
  email: string;
};

const ResetRequest: FC<{ cb: (id: string) => void }> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetRequestForm>();
  const [error, setError] = useState(false);
  const ajax = useAjax(false);
  const onSubmit = async (data: ResetRequestForm) => {
    setError(false);
    try {
      const ret = await ajax.post<{ id: string }>("/auth/reset", {
        username: data.email,
      });
      props.cb(ret.data.id);
    } catch (err) {
      setError(true);
    }
  };
  return (
    <div className="form-wrappper">
      <Card>
        <Card.Header>
          <h3>リセット要求</h3>
        </Card.Header>
        <Card.Body>
          {error && (
            <Alert variant="danger">
              入力されたメールアドレスのアカウントが見つかりません。
            </Alert>
          )}
          <p>
            パスワードをリセットしたいアカウントに登録されたメールアドレスを入力してください。
          </p>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="email"
                placeholder="例: please@entry.mail"
                {...register("email", {
                  required: "メールアドレスを指定してください",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "メールアドレスを入力してください",
                  },
                })}
                isInvalid={!!errors.email}
              />
              {errors.email && (
                <Form.Text className="text-danger">
                  {errors.email.message}
                </Form.Text>
              )}
            </Form.Group>
            <div className="text-end">
              <Button variant="primary" type="submit">
                次へ
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer>
          <div className="text-center">
            <Link to="/signin">ログインへ戻る</Link>
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
};

type ResetEntryCodeForm = {
  code: string;
};

const ResetEntryCode: FC<{
  id: string;
  cb: (code: string) => void;
  cbBack: () => void;
}> = (props) => {
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetEntryCodeForm>();
  const ajax = useAjax(false);
  const onSubmit = async (data: ResetEntryCodeForm) => {
    setError(false);
    try {
      await ajax.get<{ message: string }>(`/auth/reset/${props.id}`, {
        params: { code: data.code },
      });
      props.cb(data.code);
    } catch (err) {
      setError(true);
    }
  };
  return (
    <div className="form-wrappper">
      <Card>
        <Card.Header>
          <h3>認証コード入力</h3>
        </Card.Header>
        <Card.Body>
          {error && (
            <Alert variant="danger">
              認証に失敗しました。認証エラーが解決しない場合は再度
              <button
                type="button"
                className="btn btn-link text-decoration-none p-0 fw-bold"
                onClick={props.cbBack}
              >
                コード取得
              </button>
              を試してください。
            </Alert>
          )}
          <p>
            メールを確認してください。リセットするための認証コードが送信されています。
          </p>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Form.Group className="mb-3" controlId="code">
              <Form.Label>認証コード</Form.Label>
              <Form.Control
                type="text"
                placeholder="例: code1234"
                {...register("code", {
                  required: "認証コードを指定してください",
                })}
                isInvalid={!!errors.code}
              />
              {errors.code && (
                <Form.Text className="text-danger">
                  {errors.code.message}
                </Form.Text>
              )}
            </Form.Group>
            <p>
              メールが届かない場合、迷惑メールやスパムフォルダーなどもご確認ください。
            </p>
            <div className="text-end">
              <Button variant="primary" type="submit">
                認証
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-link text-decoration-none"
              onClick={props.cbBack}
            >
              コードが届かない場合
            </button>
          </div>
          <div className="text-center">
            <Link to="/signin">ログインへ戻る</Link>
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
};

type ResetEntryNewPwForm = {
  password: string;
};

const ResetEntryNewPwCode: FC<{
  id: string;
  code: string;
  cb: () => void;
  cbBack: () => void;
}> = (props) => {
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetEntryNewPwForm>();
  const ajax = useAjax(false);
  const onSubmit = async (data: ResetEntryNewPwForm) => {
    setError(false);
    try {
      await ajax.put<{ message: string }>(
        `/auth/reset/${props.id}`,
        { password: data.password },
        {
          params: { code: props.code },
        }
      );
      props.cb();
    } catch (err) {
      setError(true);
    }
  };
  return (
    <div className="form-wrappper">
      <Card>
        <Card.Header>
          <h3>新しいパスワード入力</h3>
        </Card.Header>
        <Card.Body>
          {error && (
            <Alert variant="danger">
              パスワードの変更に失敗しました。認証コードの有効期限が切れた可能性があります。再度
              <button
                type="button"
                className="btn btn-link text-decoration-none p-0 fw-bold"
                onClick={props.cbBack}
              >
                コード取得
              </button>
              を試してください。
            </Alert>
          )}
          <p>新規パスワードを入力してください。</p>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>パスワード</Form.Label>
              <InputGroup className="">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="例: newPass9!"
                  {...register("password", {
                    required: "パスワードを指定してください",
                    minLength: {
                      value: 8,
                      message: "8桁以上で指定してください",
                    },
                    maxLength: {
                      value: 100,
                      message: "100桁以下で指定してください",
                    },
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]{8,100}$/,
                      message: "形式を確認してください",
                    },
                  })}
                  isInvalid={!!errors.password}
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    setShowPassword((val) => !val);
                  }}
                >
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </Button>
              </InputGroup>
              <small className="text-muted">形式について</small>
              <ul className="options mb-0">
                <li>半角英数字記号のみ</li>
                <li>8桁以上100桁以下にする</li>
                <li>大文字を1文字以上使用する</li>
                <li>
                  記号 !&quot;#$%&amp;&#039;()*+,-./:;&lt;=&gt;?@[\]^_`{"{"}|
                  {"}"}~ を1文字以上使用する
                </li>
              </ul>
              {errors.password && (
                <Form.Text className="text-danger">
                  {errors.password.message}
                </Form.Text>
              )}
            </Form.Group>
            <div className="text-end">
              <Button variant="primary" type="submit">
                変更
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer>
          <div className="text-center">
            <Link to="/signin">ログインへ戻る</Link>
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
};

const ResetComplated: FC = () => {
  return (
    <div className="form-wrappper">
      <Card>
        <Card.Header>
          <h3>リセット完了</h3>
        </Card.Header>
        <Card.Body>
          <p>新しいパスワードでログインしてください。</p>
          <div className="text-center">
            <Link to="/signin" className="btn btn-primary">
              ログインへ
            </Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const Reset: FC = () => {
  const [wizard, setWizard] = useState<
    "REQUEST" | "ENTRY_CODE" | "ENTRY_NEW_PW" | "COMPLETED"
  >("REQUEST");
  const [id, setId] = useState<string>();
  const [code, setCode] = useState<string>();

  const getWizard = () => {
    switch (wizard) {
      case "ENTRY_CODE":
        return (
          <ResetEntryCode
            id={id!}
            cb={(code) => {
              setCode(code);
              setWizard("ENTRY_NEW_PW");
            }}
            cbBack={() => {
              setWizard("REQUEST");
            }}
          />
        );
      case "ENTRY_NEW_PW":
        return (
          <ResetEntryNewPwCode
            id={id!}
            code={code!}
            cb={() => {
              setWizard("COMPLETED");
            }}
            cbBack={() => {
              setWizard("REQUEST");
            }}
          />
        );
      case "COMPLETED":
        return <ResetComplated />;
      case "REQUEST":
      default:
        return (
          <ResetRequest
            cb={(id) => {
              setId(id);
              setWizard("ENTRY_CODE");
            }}
          />
        );
    }
  };

  return (
    <div className="reset">
      <Helmet>
        <title>パスワードリセット | {baseTitle}</title>
      </Helmet>
      {getWizard()}
    </div>
  );
};

export default Reset;
