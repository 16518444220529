import { FC, useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  ListGroup,
  Pagination,
  Badge,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { getToken, useAjax } from "../../../hooks/ajax";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { parse } from "query-string";
import { run as runHolder } from "holderjs";
import AdminPageBase from "../../../templates/AdminPageBase";
import {
  ProductListResponse,
  Entity,
} from "../../../../types/ProductListResponse";
import Scroll from "react-scroll";

const scroller = Scroll.scroller;

const Pagenation: FC<{
  page: number;
  count?: number;
  take: number;
  onChange?: (page: number) => void;
  id?: string;
}> = (props) => {
  if (!props.count) {
    return null;
  }
  const lastPage =
    Math.floor(props.count / props.take) + (props.count % props.take ? 1 : 0);

  const aaa = (list: number[]) => {
    return list.map((elem) => (
      <Pagination.Item
        key={elem}
        active={props.page === elem}
        onClick={() => {
          if (props.page === elem) {
            return;
          }
          props.onChange && props.onChange(elem);
        }}
      >
        {elem}
      </Pagination.Item>
    ));
  };

  if (lastPage <= 5) {
    const test = () => {
      const aaa = [];
      for (let idx = 1; idx <= lastPage; idx++) {
        aaa.push(
          <Pagination.Item
            key={idx}
            active={props.page === idx}
            onClick={() => {
              if (props.page === idx) {
                return;
              }
              props.onChange && props.onChange(idx);
            }}
          >
            {idx}
          </Pagination.Item>
        );
      }
      return aaa;
    };
    return (
      <div>
        <Pagination className="justify-content-center">
          {/* <Pagination.First
            disabled={props.page === 1}
            onClick={() => {
              props.onChange && props.onChange(1);
            }}
          /> */}
          <Pagination.Prev
            disabled={props.page === 1}
            onClick={() => {
              props.onChange && props.onChange(props.page - 1);
            }}
          />
          {test()}
          <Pagination.Next
            disabled={props.page === lastPage}
            onClick={() => {
              props.onChange && props.onChange(props.page + 1);
            }}
          />
          {/* <Pagination.Last
            disabled={props.page === lastPage}
            onClick={() => {
              props.onChange && props.onChange(lastPage);
            }}
          /> */}
        </Pagination>
      </div>
    );
  }

  if (props.page <= 3) {
    return (
      <div>
        <Pagination className="justify-content-center">
          <Pagination.Prev
            disabled={props.page === 1}
            onClick={() => {
              props.onChange && props.onChange(props.page - 1);
            }}
          />
          {aaa([1, 2, 3, 4])}
          <Pagination.Ellipsis disabled />
          {aaa([lastPage])}
          <Pagination.Next
            disabled={props.page === lastPage}
            onClick={() => {
              props.onChange && props.onChange(props.page + 1);
            }}
          />
        </Pagination>
      </div>
    );
  }
  if (props.page >= lastPage - 2) {
    return (
      <div>
        <Pagination className="justify-content-center">
          <Pagination.Prev
            disabled={props.page === 1}
            onClick={() => {
              props.onChange && props.onChange(props.page - 1);
            }}
          />
          {aaa([1])}
          <Pagination.Ellipsis disabled />
          {aaa([lastPage - 3, lastPage - 2, lastPage - 1, lastPage])}
          <Pagination.Next
            disabled={props.page === lastPage}
            onClick={() => {
              props.onChange && props.onChange(props.page + 1);
            }}
          />
        </Pagination>
      </div>
    );
  }
  return (
    <div>
      <Pagination className="justify-content-center">
        <Pagination.Prev
          disabled={props.page === 1}
          onClick={() => {
            props.onChange && props.onChange(props.page - 1);
          }}
        />
        {aaa([1])}
        <Pagination.Ellipsis disabled />
        {aaa([props.page - 1, props.page, props.page + 1])}
        <Pagination.Ellipsis disabled />
        {aaa([lastPage])}
        <Pagination.Next
          disabled={props.page === lastPage}
          onClick={() => {
            props.onChange && props.onChange(props.page + 1);
          }}
        />
      </Pagination>
    </div>
  );
  // if (!props.count || !props.page || !props.take) {

  //   return (
  //     <Pagenation>
  //       <Pagination.First />
  //       <Pagination.Prev />
  //       <Pagination.Item>{1}</Pagination.Item>
  //       <Pagination.Ellipsis disabled />
  //       <Pagination.Next />
  //       <Pagination.Last />
  //     </Pagenation>
  //   );
  //   // console.log("aaa")
  // }
  // return (
  //   <Pagination>
  //     <Pagination.First />
  //     <Pagination.Prev />
  //     <Pagination.Item>{1}</Pagination.Item>
  //     <Pagination.Ellipsis disabled />

  //     <Pagination.Item>{10}</Pagination.Item>
  //     <Pagination.Item>{11}</Pagination.Item>
  //     <Pagination.Item active>{12}</Pagination.Item>
  //     <Pagination.Item>{13}</Pagination.Item>
  //     <Pagination.Item disabled>{14}</Pagination.Item>

  //     <Pagination.Ellipsis />
  //     <Pagination.Item>{20}</Pagination.Item>
  //     <Pagination.Next />
  //     <Pagination.Last />
  //   </Pagination>
  // );
};

const AdminProductsList: FC = () => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [token, setToken] = useState<string>();
  const { reset, getValues, handleSubmit } = useForm<{
    email: string;
    password: string;
    statuses: { pendingVerification: boolean; confirmed: boolean };
  }>({
    reValidateMode: "onChange",
    defaultValues: {
      statuses: { pendingVerification: true, confirmed: true },
    },
  });
  const ajax = useAjax();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const { q } = parse(location.search) as { q: string };
      if (!q) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      }
      const { page, take, statuses, to } = JSON.parse(q) as {
        page: number;
        take: number;
        statuses: { pendingVerification: boolean; confirmed: boolean };
        to?: string;
      };
      const filter = {
        statuses: [] as string[],
      };
      // const statuses = getValues("statuses");
      if (statuses.pendingVerification) {
        filter.statuses.push("PENDING_VERIFICATION");
      }
      if (statuses.confirmed) {
        filter.statuses.push("CONFIRMED");
      }
      const res = await ajax.get<ProductListResponse>(
        "/api/operator/products",
        {
          params: { filter, take, skip: take * (page - 1) },
        }
      );
      setToken(await getToken());
      reset({ statuses });
      setProducts(res.data.entities);
      setCount(res.data.count);
      setPage(page);
      setTake(take);
      if (to) {
        scroller.scrollTo(to, { duration: 200 });
      } else {
        scroller.scrollTo("result-top", { duration: 200 });
      }
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const myImage = document.getElementsByClassName(
    "holder-img"
  ) as unknown as HTMLElement;
  runHolder({ images: myImage });

  const navigate = useNavigate();
  const [products, setProducts] = useState<Entity[]>([]);

  const onSubmit = async (data: {
    email: string;
    password: string;
    statuses: { pendingVerification: boolean; confirmed: boolean };
  }) => {
    const query = { ...data, page: 1, take };
    navigate({
      search: `?q=${JSON.stringify(query)}`,
    });
  };

  const label = (product: Entity) => {
    const {
      stockQuantity,
      inStockSoon,
      specialPrice,
      onlyFewLeft,
    } = product;
    if (stockQuantity === 0) {
      if (inStockSoon) {
        return (
          <div className="badge bg-secondary">
            売り切れ<span>(入荷中)</span>
          </div>
        );
      }
      return (
        <div className="badge bg-secondary">
          売り切れ<span>(入荷未定)</span>
        </div>
      );
    }
    if (onlyFewLeft) {
      return <div className="badge bg-warning">残りわずか</div>;
    }
    if (specialPrice) {
      return <div className="badge bg-danger">セール</div>;
    }
    return null;
  };

  const data = getValues();
  const query = { ...data, page, take };
  return (
    <AdminPageBase backTo="/admin" pageTitle="[管理]商品一覧">
      <Container>
        <h2>商品一覧</h2>
        <div className="card mb-3">
          <div className="card-header">検索条件</div>
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              {/* <div className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="checkbox-1"
                  label="確認待ち"
                  {...register("statuses.pendingVerification")}
                />
              </div>
              <div className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="checkbox-2"
                  label="確認済"
                  {...register("statuses.confirmed")}
                />
              </div> */}
              <div className="text-end">
                <Button variant="primary" type="submit">
                  検索
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex">
            <div className="form-control-plaintext w-auto me-2">件数</div>
            <div>
              <Form.Select
                aria-label="Default select example"
                value={take}
                onChange={async (e) => {
                  const { statuses } = getValues();
                  const query = { statuses, page: 1, take: e.target.value };
                  navigate({
                    search: `?q=${JSON.stringify(query)}`,
                  });
                }}
              >
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Form.Select>
            </div>
          </div>
          <div>
            <Link to="/admin/products/register" className="btn btn-primary">
              新規追加
            </Link>
          </div>
        </div>

        <div id="result-top" />
        <Pagenation
          page={page}
          count={count}
          take={take}
          onChange={async (page) => {
            const { statuses } = getValues();
            const query = { statuses, page, take };
            navigate({
              search: `?q=${JSON.stringify(query)}`,
            });
          }}
        />

        {products && (
          <ListGroup className="mb-3">
            {products.map((product, idx) => (
              <Link
                key={idx}
                to={`/admin/products/${product.id}?q=${JSON.stringify(
                  Object.assign(query, { to: product.id.toString() })
                )}`}
                className="list-group-item list-group-item-action"
                id={`${product.id}`}
              >
                <div className="clearfix">
                  {product.featuredImageFileName ? (
                    <img
                      src={`/api/operator/products/images/${product.featuredImageFileName}_350x350?token=${token}`}
                      className="holder-img float-start rounded me-2"
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src="/no_image.svg"
                      className="holder-img float-start rounded me-2"
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <div>
                    {product.isOpen ? (
                      <Badge className="me-1" bg="success">
                        公開済
                      </Badge>
                    ) : (
                      <Badge className="me-1" bg="secondary">
                        公開前
                      </Badge>
                    )}
                    {label(product)}
                    <h5 className="text-truncate">{product.name}</h5>
                    {!(product.specialPrice !== undefined && product.specialPriceTax !== undefined) && (
                      <div className="small text-muted">
                        定価{" "}
                        {Math.floor(
                          product.price * (1 + product.priceTax / 100)
                        ).toLocaleString()}{" "}
                        円
                      </div>
                    )}
                    {product.specialPrice !== undefined && product.specialPriceTax !== undefined && (
                      <div className="small text-danger">
                        特価{" "}
                        {Math.floor(
                          product.specialPrice *
                            (1 + product.specialPriceTax / 100)
                        ).toLocaleString()}{" "}
                        円
                      </div>
                    )}
                    <div className="small text-muted">
                      残り {product.stockQuantity.toLocaleString()} {product.stockQuantityUnit || "個"}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </ListGroup>
        )}

        <Pagenation
          page={page}
          count={count}
          take={take}
          onChange={async (page) => {
            const { statuses } = getValues();
            const query = { statuses, page, take };
            navigate({
              search: `?q=${JSON.stringify(query)}`,
            });
          }}
        />
      </Container>
    </AdminPageBase>
  );
};

export default AdminProductsList;
