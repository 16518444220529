import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BasicPageBase from "../../templates/BasicPageBase";

const title = "特定商取引法に基づく表示";
const description = "特定商取引法に基づく表示に関する情報を公開しているページです。";

const SpecifiedCommercialTransactionsLaw: FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <BasicPageBase>
      <Helmet>
        <title>{title} | confidence cigar</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Container className="mt-4">
        <h3 className="mb-5">特定商取引法に基づく表示</h3>
        <dl className="mb-5">
          <dt className="mb-2">ショップ名</dt>
          <dd className="mb-3 ms-1 ms-sm-3">confidence cigar -葉巻卸販売-</dd>
          <dt className="mb-2">当サイトに関して</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            confidence-cigar
            -葉巻卸販売-（株式会社confidence、以下当社）は製造煙草の特定販売業（輸入煙草の卸売販売業）を行っております。当社はHabanos
            S.A.認定業者ではございませんが、すべての葉巻は認定サプライヤーから直接、または間接的に仕入れております。そのためHabanos
            S.A.の保証は当社販売の葉巻には適用されません。
            <br />
            販売品に関する保証は当社による提供のみとなりますのでご了承下さい。
          </dd>
          <dt className="mb-2">運営責任者</dt>
          <dd className="mb-3 ms-1 ms-sm-3">松浦 大樹</dd>
          <dt className="mb-2">運営会社</dt>
          <dd className="mb-3 ms-1 ms-sm-3">株式会社confidence</dd>
          <dt className="mb-2">所在地</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            〒144-0043
            <br />
            東京都大田区羽田3-1-7
          </dd>
          <dt className="mb-2">電話番号</dt>
          <dd className="mb-3 ms-1 ms-sm-3">080-6287-1024</dd>
          <dt className="mb-2">eメールアドレス</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            contact
            <FontAwesomeIcon icon={faStar} />
            confidence-cigar.com
            <br />
            <small className="text-muted">
              ※ 実際にメールを送る際は「
              <FontAwesomeIcon icon={faStar} />
              」を「@」に置き換えてください
            </small>
          </dd>
          <dt className="mb-2">ホームページアドレス</dt>
          <dd className="mb-3 ms-1 ms-sm-3">https://confidence-cigar.com/</dd>
          <dt className="mb-2">営業時間</dt>
          <dd className="mb-3 ms-1 ms-sm-3">09:00〜21:00</dd>
          <dt className="mb-2">定休日</dt>
          <dd className="mb-3 ms-1 ms-sm-3">日曜日</dd>
          <dt className="mb-2">ご注文方法</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            メールにてお見積りを承っております。
            <br />
            お見積り内容に問題がございませんでしたら、発注書のご送付、お振込みをお願い致します。
            <br />
            定休日、年末年始はお休みを頂いておりますので、ご質問へのお返事、ご注文確認メール送信は翌営業日となります。
            <br />
            当店からはご注文受領確認後と発送完了時にご連絡のメールをさせて頂いております。
            <br />
            メールが届かない場合は大変お手数ですがご連絡下さい。
          </dd>
          <dt className="mb-2">商品代金以外の付帯費用等</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            消費税、送料、振込手数料が発生致します。
          </dd>
          <dt className="mb-2">送料（消費税込み）</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            北海道 1,300円
            <br />
            東北、北陸、信越、東海、関東 850円
            <br />
            近畿 970円
            <br />
            中国、四国 1,100円
            <br />
            九州、沖縄 1,300円
            <br />
            離島・一部地域にて追加送料がかかる事はございません。
          </dd>
          <dt className="mb-2">返品に関する事項</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            商品の性質上、お客様都合による注文のキャンセル、または変更は承っておりません。
            <br />
            不良・欠陥品である場合に限り、商品到着後3日以内にメール、またはお電話にてお知らせください。
            <br />
            返品送料当社負担にてご対応させて頂きます。
          </dd>
          <dt className="mb-2">販売数量</dt>
          <dd className="mb-3 ms-1 ms-sm-3">1本から承っております。</dd>
          <dt className="mb-2">支払方法</dt>
          <dd className="mb-3 ms-1 ms-sm-3">銀行振込</dd>
          <dt className="mb-2">発送方法</dt>
          <dd className="mb-3 ms-1 ms-sm-3">ゆうパック</dd>
          <dt className="mb-2">引き渡し時期</dt>
          <dd className="mb-3 ms-1 ms-sm-3">
            お振込確認後から3営業日以内に発送致します。
            <br />
            在庫切れ、商品トラブル等により納品が遅れる場合にはご連絡致します。
          </dd>
        </dl>
      </Container>
    </BasicPageBase>
  );
};

export default SpecifiedCommercialTransactionsLaw;
