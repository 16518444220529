import {
  faMinus,
  faPlus,
  faRefresh,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createContext,
  FC,
  useEffect,
  useState,
  useContext,
  Dispatch,
} from "react";
import {
  Alert,
  Button,
  Card,
  Container,
  Form,
  Badge,
  InputGroup,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import axios, { AxiosError } from "axios";
import { useAjax, getUser } from "../../hooks/ajax";
import BasicPageBase from "../../templates/BasicPageBase";
import { getCarts, removeCart, setCart } from "../products/ProductDetail";
import { Product } from "../products/Products";
import "./Quotation.scss";

const title = "お見積";
const description = "お見積りをリクエストできるページです。";

type QuotationShippingAddressForm = {
  name: string;
  tel: string;
  postCode: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
};

interface ZipCloudResponseResult {
  address1: string;
  address2: string;
  address3?: string;
  kana1: string;
  kana2: string;
  kana3?: string;
  prefcode: string;
  zipcode: string;
}

interface ZipCloudResponse {
  message?: string;
  results?: ZipCloudResponseResult[];
  status: 200 | 400;
}

interface ShippingAddress {
  id: number;
  name: string;
  tel: string;
  postCode: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  isDefault: boolean;
  latestUsage?: Date;
}

interface Errors {
  message: string;
  currentStockQuantity: number;
}

interface ErrorResponse {
  message: string;
  errors: { [id: string]: Errors };
}

const prefectures = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

const ShippingAddressView: FC = () => {
  const { setViewStatus, shippingAddresses, selected } =
    useContext(QuotationContext);
  if (shippingAddresses.length === 0) {
    return <></>;
  }
  const selectedShippingAddresses = shippingAddresses.filter(
    (address) => address.id === selected
  );
  if (selectedShippingAddresses.length === 0) {
    return <></>;
  }
  const selectedShippingAddress = selectedShippingAddresses[0];
  return (
    <>
      <div className="d-flex">
        <div className="flex-fill">
          <div className="fw-bold">{selectedShippingAddress.name}</div>

          <div>〒{selectedShippingAddress.postCode}</div>
          <div>
            {selectedShippingAddress.address1}{" "}
            {selectedShippingAddress.address2}{" "}
            {selectedShippingAddress.address3}{" "}
            {selectedShippingAddress.address4}
          </div>
          <div className="small">
            電話番号:
            <span className="ms-1">{selectedShippingAddress.tel}</span>
          </div>
        </div>
        <div className="text-end" style={{ width: "3rem" }}>
          <Button
            variant="link"
            size="sm"
            className="p-0 text-decoration-none"
            onClick={() => {
              setViewStatus("SELECT");
              scroller.scrollTo("shippingAddressElem", {
                smooth: false,
              });
            }}
          >
            変更
          </Button>
        </div>
      </div>
    </>
  );
};

const ShippingAddressSelect: FC = () => {
  const {
    setViewStatus: changeMode,
    shippingAddresses,
    selected,
    setSelected,
    setModifyTarget,
  } = useContext(QuotationContext);
  const [curSelected, setCurSelected] = useState<number>(selected);
  if (shippingAddresses.length === 0) {
    return <></>;
  }
  const defaultShippingAddress = shippingAddresses.filter(
    (address) => address.isDefault
  )[0];
  const notDefaultShippingAddresses = shippingAddresses.filter(
    (address) => !address.isDefault
  );
  return (
    <>
      <h5 className="h6 mb-0 fw-bold">デフォルトの配送先</h5>
      <hr className="mt-1 mb-0" />
      <div className="text-muted small mb-3">
        会員情報の担当者様および会社情報から自動生成された住所です
      </div>
      {defaultShippingAddress && (
        <Form.Check type="radio" id={`${defaultShippingAddress.id}`}>
          <div className={`d-flex`}>
            <div className="p-1 d-flex align-items-center">
              <Form.Check.Input
                type="radio"
                checked={curSelected === defaultShippingAddress.id}
                onChange={() => {
                  setCurSelected(defaultShippingAddress.id);
                }}
              />
            </div>
            <div className="p-1 flex-fill">
              <Form.Check.Label className="w-100">
                <div className="fw-bold">{defaultShippingAddress.name}</div>

                <div>〒{defaultShippingAddress.postCode}</div>
                <div>
                  {defaultShippingAddress.address1}{" "}
                  {defaultShippingAddress.address2}{" "}
                  {defaultShippingAddress.address3}{" "}
                  {defaultShippingAddress.address4}
                </div>
                <div className="small">
                  電話番号:
                  <span className="ms-1">{defaultShippingAddress.tel}</span>
                </div>
              </Form.Check.Label>
            </div>
          </div>
        </Form.Check>
      )}
      <h5 className="h6 mt-4 mb-0 fw-bold">その他の配送先</h5>
      <hr className="mt-1" />
      {notDefaultShippingAddresses.map((address, idx) => (
        <Form.Check key={address.id} type="radio" id={`${address.id}`}>
          <div className={`d-flex${idx !== 0 ? " mt-4" : ""}`}>
            <div className="p-1 d-flex align-items-center">
              <Form.Check.Input
                type="radio"
                checked={curSelected === address.id}
                onChange={() => {
                  setCurSelected(address.id);
                }}
              />
            </div>
            <div className="p-1 flex-fill">
              <Form.Check.Label className="w-100">
                <div className="fw-bold">{address.name}</div>

                <div>〒{address.postCode}</div>
                <div>
                  {address.address1} {address.address2} {address.address3}{" "}
                  {address.address4}
                </div>
                <div className="small">
                  電話番号:<span className="ms-1">{address.tel}</span>
                </div>
                <Button
                  variant="link"
                  size="sm"
                  className="p-0 text-decoration-none"
                  onClick={() => {
                    setModifyTarget(address.id);
                    changeMode("MODIFY");
                    scroller.scrollTo("shippingAddressElem", {
                      smooth: false,
                    });
                  }}
                >
                  住所を編集
                </Button>
              </Form.Check.Label>
            </div>
          </div>
        </Form.Check>
      ))}
      <Button
        variant="link"
        onClick={() => {
          setModifyTarget(undefined);
          changeMode("MODIFY");
          scroller.scrollTo("shippingAddressElem", {
            smooth: false,
          });
        }}
        className="ps-1 pe-1 mt-1"
      >
        <FontAwesomeIcon icon={faPlus} className="me-1" />
        新規追加
      </Button>
      <div className="text-end mt-4">
        <Button
          variant="warning"
          onClick={() => {
            setSelected(curSelected);
            changeMode("VIEW");
            scroller.scrollTo("shippingAddressElem", {
              smooth: false,
            });
          }}
        >
          この住所に発送
        </Button>
      </div>
    </>
  );
};

const ShippingAddressModify: FC = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<QuotationShippingAddressForm>();
  const {
    setViewStatus: changeMode,
    setSelected,
    reloadShippingAddresses,
    shippingAddresses,
    modifyTarget,
  } = useContext(QuotationContext);
  const ajaxNonAuth = useAjax(false);
  const ajax = useAjax();

  useEffect(() => {
    (async () => {
      if (!modifyTarget) {
        return;
      }
      const { name, tel, postCode, address1, address2, address3, address4 } =
        shippingAddresses.filter((address) => address.id === modifyTarget)[0];
      reset({ name, tel, postCode, address1, address2, address3, address4 });
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const address1 = watch("address1");

  const onSubmit = async (data: QuotationShippingAddressForm) => {
    let result: { message: string; ret: ShippingAddress };
    if (modifyTarget) {
      const ret = await ajax.put<{ message: string; ret: ShippingAddress }>(
        `/api/member/oneself/shipping-addresses/${modifyTarget}`,
        data
      );
      result = ret.data;
    } else {
      const ret = await ajax.post<{ message: string; ret: ShippingAddress }>(
        "/api/member/oneself/shipping-addresses",
        data
      );
      result = ret.data;
    }
    await reloadShippingAddresses();
    setSelected(result.ret.id);
    changeMode("SELECT");
    scroller.scrollTo("shippingAddressElem", {
      smooth: false,
    });
  };

  const autocomplateAddress = async () => {
    const postCode = getValues("postCode");
    if (!postCode || postCode.length !== 7) {
      return;
    }
    const ret = await ajaxNonAuth.get<ZipCloudResponse>(
      "https://zipcloud.ibsnet.co.jp/api/search",
      {
        params: { zipcode: postCode },
      }
    );
    if (ret.data.status !== 200 || !ret.data.results) {
      return;
    }
    console.log(ret.data.results[0]);
    setValue("address1", ret.data.results[0].address1, {
      shouldValidate: true,
    });
    setValue("address2", ret.data.results[0].address2, {
      shouldValidate: true,
    });
    if (ret.data.results[0].address3) {
      setValue("address3", ret.data.results[0].address3, {
        shouldValidate: true,
      });
    }
  };

  return (
    <>
      <h5 className="h6 mb-0 fw-bold">{modifyTarget ? "編集" : "新規追加"}</h5>
      <hr className="mt-1 mb-4" />
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3 row" controlId="name">
          <Form.Label className="col-md-4">
            氏名
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <div className="col-md-8">
            <Form.Control
              type="text"
              placeholder="例: 今府井 殿助"
              {...register("name", { required: "指定してください" })}
              isInvalid={!!errors.name}
            />
            {errors.name && (
              <Form.Text className="text-danger">
                {errors.name.message}
              </Form.Text>
            )}
          </div>
        </Form.Group>
        <Form.Group className="mb-3 row" controlId="tel">
          <Form.Label className="col-md-4">
            電話番号
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <div className="col-md-8">
            <Form.Control
              type="tel"
              placeholder="例: 08012345678"
              {...register("tel", {
                required: "指定してください",
                pattern: {
                  value: /^0[0-9]{9,10}$/,
                  message: "0始まり10〜11桁の数値を指定してください",
                },
              })}
              isInvalid={!!errors.tel}
              maxLength={11}
            />
            {errors.tel && (
              <Form.Text className="text-danger d-block">
                {errors.tel.message}
              </Form.Text>
            )}
            <Form.Text className="text-muted">
              配達をサポートする際に利用される場合があります。
            </Form.Text>
          </div>
        </Form.Group>
        <Form.Group className="mb-3 row" controlId="postCode">
          <Form.Label className="col-md-4">
            郵便番号
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <div className="col-md-8">
            <InputGroup>
              <Form.Control
                type="tel"
                placeholder="例: 1234567"
                {...register("postCode", {
                  required: "指定してください",
                  pattern: {
                    value: /^[0-9]{7}$/,
                    message: "7桁の数値を指定してください",
                  },
                })}
                isInvalid={!!errors.postCode}
                maxLength={7}
              />
              <Button
                variant="outline-secondary"
                id="autocomplate"
                onClick={() => autocomplateAddress()}
              >
                自動入力
              </Button>
            </InputGroup>
            {errors.postCode && (
              <Form.Text className="text-danger">
                {errors.postCode.message}
              </Form.Text>
            )}
          </div>
        </Form.Group>
        <Form.Group className="mb-3 row" controlId="address1">
          <Form.Label className="col-md-4">
            都道府県
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <div className="col-md-8">
            <Form.Select
              className={`${address1 ? "" : "text-muted"}`}
              {...register("address1", {
                required: "選択してください",
              })}
              isInvalid={!!errors.address1}
            >
              <option value="">例: 東京都</option>
              {prefectures.map((prefecture, idx) => (
                <option key={idx} value={prefecture}>
                  {prefecture}
                </option>
              ))}
            </Form.Select>
            {errors.address1 && (
              <Form.Text className="text-danger">
                {errors.address1.message}
              </Form.Text>
            )}
          </div>
        </Form.Group>
        <Form.Group className="mb-3 row" controlId="address2">
          <Form.Label className="col-md-4">
            市区町村
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <div className="col-md-8">
            <Form.Control
              type="text"
              placeholder="例: 大田区"
              {...register("address2", {
                required: "指定してください",
              })}
              isInvalid={!!errors.address2}
              maxLength={100}
            />
            {errors.address2 && (
              <Form.Text className="text-danger">
                {errors.address2.message}
              </Form.Text>
            )}
          </div>
        </Form.Group>
        <Form.Group className="mb-3 row" controlId="address3">
          <Form.Label className="col-md-4">
            番地
            <Badge bg="danger" className="ms-1">
              必須
            </Badge>
          </Form.Label>
          <div className="col-md-8">
            <Form.Control
              type="text"
              placeholder="例: ○○ 1丁目 1番 3号"
              {...register("address3", {
                required: "指定してください",
              })}
              isInvalid={!!errors.address3}
              maxLength={100}
            />
            {errors.address3 && (
              <Form.Text className="text-danger">
                {errors.address3.message}
              </Form.Text>
            )}
          </div>
        </Form.Group>
        <Form.Group className="mb-3 row" controlId="address4">
          <Form.Label className="col-md-4">建物・部屋番号</Form.Label>
          <div className="col-md-8">
            <Form.Control
              type="text"
              placeholder="例: ○○ビル 101号室"
              {...register("address4")}
              maxLength={100}
            />
          </div>
        </Form.Group>
        <div className="text-end mt-4">
          <Button
            variant="secondary"
            type="button"
            className="me-1"
            onClick={() => {
              changeMode("SELECT");
              scroller.scrollTo("shippingAddressElem", {
                smooth: false,
              });
            }}
          >
            キャンセル
          </Button>
          <Button variant="primary" type="submit">
            {modifyTarget ? "更新" : "登録"}
          </Button>
        </div>
      </Form>
    </>
  );
};

type UserStatus = "NOTSIGNINED" | "NOTALLOWED" | "ALLOWED" | "UNKNOWN";
type ViewStatus =
  | "NOTINITIALIZED"
  | "VIEW"
  | "SELECT"
  | "MODIFY"
  | "UPDATE"
  | "COMPLATED";
const QuotationContext = createContext<{
  viewStatus: ViewStatus;
  userStatus: UserStatus;
  products?: (Product & { num: number })[];
  shippingAddresses: ShippingAddress[];
  selected: number;
  modifyTarget?: number;
  setViewStatus: (status: ViewStatus) => void;
  checkUserStatus: () => Promise<UserStatus>;
  setSelected: (id: number) => void;
  setProducts: Dispatch<
    React.SetStateAction<
      | (Product & {
          num: number;
        })[]
      | undefined
    >
  >;
  reloadShippingAddresses: () => Promise<ShippingAddress[]>;
  setModifyTarget: (id?: number) => void;
}>({
  viewStatus: "NOTINITIALIZED",
  userStatus: "UNKNOWN",
  products: undefined,
  shippingAddresses: [],
  selected: 0,
  modifyTarget: undefined,
  checkUserStatus: async () => "UNKNOWN",
  setViewStatus: (_) => {},
  setSelected: (_) => {},
  setProducts: (_) => _,
  reloadShippingAddresses: async () => [],
  setModifyTarget: (_) => {},
});

const Request: FC = () => {
  const {
    viewStatus,
    userStatus,
    products,
    shippingAddresses,
    selected,
    setViewStatus,
    setProducts,
    checkUserStatus,
  } = useContext(QuotationContext);
  const [remarks, setRemarks] = useState<string>();
  const [errors, setErrors] = useState<{
    [id: string]: { message: string; currentStockQuantity: number };
  }>();
  const ajax = useAjax();

  const request = async () => {
    if (!products) {
      return;
    }
    const requestProducts: { [id: string]: number } = {};
    for (const product of products) {
      requestProducts[product.id.toString()] = product.num;
    }
    try {
      await ajax.post("/api/member/oneself/orders", {
        products: requestProducts,
        shippingAddressId: selected,
        remarks: remarks || undefined,
      });
      removeCart();
      setViewStatus("COMPLATED");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        throw err;
      }
      const error = err as AxiosError<ErrorResponse>;
      if (!error.response || error.response.status !== 400) {
        throw err;
      }
      if (error.response.data.message === "not found confirmed member.") {
        await checkUserStatus();
        scroller.scrollTo("alertElem", { smooth: false });
        return;
      }
      // console.log(error.response.data);
      setErrors(error.response.data.errors);
      setProducts((products) =>
        !products
          ? undefined
          : products.map((product) =>
              error.response?.data.errors[product.id.toString()]
                ? {
                    ...product,
                    stockQuantity:
                      error.response.data.errors[product.id.toString()]
                        .currentStockQuantity,
                  }
                : product
            )
      );
      scroller.scrollTo("productsElem", {
        smooth: false,
      });
    }
  };

  const getTotal = () => {
    if (!products || products.length === 0) {
      return 0;
    }
    return products
      .map((product) =>
        Math.floor(
          getRealProductPriceAndTax(product).price *
            product.num *
            (1 + getRealProductPriceAndTax(product).tax / 100)
        )
      )
      .reduce((sum, elem) => sum + elem);
  };

  const getRealProductPriceAndTax = (product: Product) => {
    if (product.specialPrice && product.specialPriceTax) {
      return {
        price: product.specialPrice,
        tax: product.specialPriceTax,
      };
    }
    return {
      price: product.price,
      tax: product.priceTax,
    };
  };

  if (products && products.length === 0) {
    return (
      <>
        <Alert variant="secondary">商品が選択されていません。</Alert>
        <div className="row flex-md-row-reverse position-relative pt-2 pb-2">
          <div className="col-md-4">
            <div className="position-relative mb-4">
              <Card>
                <Card.Body>
                  <Card.Title>合計(税込)</Card.Title>
                  <Card.Text>0 円</Card.Text>
                  <div className="d-grid gap-2">
                    <Button variant="warning" className="disabled">
                      見積もりへ
                    </Button>
                  </div>
                </Card.Body>
              </Card>
              <div
                className={`blocking-overlay${
                  userStatus === "ALLOWED" && viewStatus === "VIEW"
                    ? " d-none"
                    : ""
                }`}
              ></div>
            </div>
          </div>
          <div className="col-md-8"></div>
        </div>
      </>
    );
  }

  return (
    <>
      {userStatus === "NOTSIGNINED" && (
        <div className="row">
          <div className="col-sm mb-4">
            <h4 className="h5">ログイン</h4>
            <p>会員登録済みの方はこちら</p>
            <Link to="/signin?redirect=/quotation" className="btn btn-warning">
              ログイン
            </Link>
          </div>
          <div className="col-sm mb-4">
            <h4 className="h5">新規会員登録</h4>
            <p>会員登録される方はこちら</p>
            <Link to="/member/register" className="btn btn-warning">
              会員登録
            </Link>
          </div>
        </div>
      )}
      {userStatus === "NOTALLOWED" && (
        <>
          <Alert variant="secondary">
            <Element name="alertElem"></Element>
            <div className="d-flex">
              <div className="flex-fill">
                <h4 className="h5">登録情報の確認中です</h4>
              </div>
              <div>
                <Button
                  onClick={async () => {
                    await checkUserStatus();
                  }}
                  variant="link"
                  className="mt-n1 p-0 text-secondary"
                >
                  <FontAwesomeIcon icon={faRefresh} />
                </Button>
              </div>
            </div>
            <p className="mb-0">
              ご登録情報が確認できるまで最大で3営業日ほどお時間をいただくことがあります。3営業日が過ぎても状態が変わらない場合は{" "}
              <Link to="/contact-us">こちら</Link> までお問い合わせください。
            </p>
          </Alert>
        </>
      )}
      <div className="row flex-md-row-reverse position-relative pt-2 pb-2">
        <div className="col-md-4">
          <div className="position-relative mb-4">
            <Card>
              <Card.Body>
                <Card.Title>合計(税込)</Card.Title>
                <Card.Text>{getTotal().toLocaleString()} 円</Card.Text>
                <div className="d-grid gap-2">
                  <Button
                    variant="warning"
                    className={`${
                      products && products.length === 0 ? "disabled" : ""
                    }`}
                    onClick={() => request()}
                  >
                    見積もりへ
                  </Button>
                </div>
              </Card.Body>
            </Card>
            <div
              className={`blocking-overlay${
                userStatus === "ALLOWED" && viewStatus === "VIEW"
                  ? " d-none"
                  : ""
              }`}
            ></div>
          </div>
        </div>
        <div className="col-md-8">
          {shippingAddresses.length !== 0 && (
            <>
              <div className="position-relative pt-2">
                <div>
                  <Element name="shippingAddressElem"></Element>
                  <h4 className="h5">お届け先</h4>
                  <Card>
                    <Card.Body>
                      {viewStatus === "VIEW" && <ShippingAddressView />}
                      {viewStatus === "SELECT" && <ShippingAddressSelect />}
                      {viewStatus === "MODIFY" && <ShippingAddressModify />}
                    </Card.Body>
                  </Card>
                </div>
                <div
                  className={`blocking-overlay${
                    userStatus === "ALLOWED" ? " d-none" : ""
                  }`}
                ></div>
              </div>
              <hr />
            </>
          )}
          <div className="position-relative pt-2">
            <div>
              <Element name="productsElem"></Element>
              <h4 className="h5">商品の確認</h4>
              {errors && (
                <Alert variant="danger">
                  カート内の商品の数量が足りないようです。実在する数量を指定し直してください。自動で数量調整する場合は
                  <Button
                    variant="link"
                    className="p-0 text-danger mt-n1"
                    onClick={() => {
                      setProducts((products) =>
                        !products
                          ? undefined
                          : products
                              .map((product) =>
                                errors[product.id.toString()]
                                  ? {
                                      ...product,
                                      num: errors[product.id]
                                        .currentStockQuantity,
                                    }
                                  : product
                              )
                              .filter((product) => product.num !== 0)
                      );
                      for (const [id, value] of Object.entries(errors)) {
                        setCart(+id, { num: value.currentStockQuantity });
                      }
                      setErrors(undefined);
                      scroller.scrollTo("productsElem", {
                        smooth: false,
                      });
                    }}
                  >
                    こちら
                  </Button>
                </Alert>
              )}
              {products &&
                products.map((product) => (
                  <Card
                    key={product.id}
                    className={`${
                      errors && errors[product.id.toString()]
                        ? "border-danger"
                        : ""
                    }`}
                  >
                    <Card.Body>
                      <div className="d-flex flex-column flex-sm-row">
                        <div>
                          <Card.Img
                            src={`/api/anyone/products/images/${product.featuredImageFileName}_350x350`}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                        <div className="ps-sm-3">
                          <Card.Title>{product.name}</Card.Title>
                          <Card.Text
                            className={`${
                              product.specialPrice && product.specialPriceTax
                                ? "mb-0 text-decoration-line-through"
                                : "mb-1"
                            }`}
                          >
                            国内定価{" "}
                            {Math.floor(
                              product.price * (1 + product.priceTax / 100)
                            ).toLocaleString()}{" "}
                            円
                          </Card.Text>
                          {product.specialPrice && product.specialPriceTax && (
                            <Card.Text className="mb-1 text-danger">
                              特価{" "}
                              {Math.floor(
                                product.specialPrice *
                                  (1 + product.specialPriceTax / 100)
                              ).toLocaleString()}{" "}
                              円
                            </Card.Text>
                          )}
                          <Card.Text
                            className={`mb-0${
                              errors && errors[product.id.toString()]
                                ? " text-danger"
                                : ""
                            }`}
                          >
                            数量 {product.num}
                            {errors && errors[product.id.toString()] && (
                              <span className="ms-1 small">
                                (実在する個数:{" "}
                                {errors &&
                                  errors[product.id.toString()]
                                    .currentStockQuantity}
                                )
                              </span>
                            )}
                          </Card.Text>
                          <Card.Text>
                            小計{" "}
                            {Math.floor(
                              getRealProductPriceAndTax(product).price *
                                product.num *
                                (1 +
                                  getRealProductPriceAndTax(product).tax / 100)
                            ).toLocaleString()}{" "}
                            円
                          </Card.Text>
                          <div>
                            <Button
                              variant="outline-secondary"
                              className="rounded-circle"
                              size="sm"
                              disabled={product.num <= 1}
                              onClick={() => {
                                const curNum = product.num;
                                setProducts((products) => {
                                  if (!products) {
                                    return undefined;
                                  }
                                  const newProducts = [...products];
                                  newProducts.filter(
                                    (target) => target.id === product.id
                                  )[0].num = curNum - 1;
                                  return newProducts;
                                });
                                setCart(product.id, { num: curNum - 1 });
                                if (
                                  errors &&
                                  errors[product.id.toString()] &&
                                  errors[product.id.toString()]
                                    .currentStockQuantity >=
                                    curNum - 1
                                ) {
                                  setErrors((errors) => {
                                    if (!errors) {
                                      return undefined;
                                    }
                                    delete errors[product.id.toString()];
                                    if (Object.keys(errors).length === 0) {
                                      return undefined;
                                    }
                                    return errors;
                                  });
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                            <Button
                              variant="outline-secondary"
                              className="rounded-circle"
                              size="sm"
                              disabled={product.num >= product.stockQuantity}
                              onClick={() => {
                                const curNum = product.num;
                                setProducts((products) => {
                                  if (!products) {
                                    return undefined;
                                  }
                                  const newProducts = [...products];
                                  newProducts.filter(
                                    (target) => target.id === product.id
                                  )[0].num = curNum + 1;
                                  return newProducts;
                                });
                                setCart(product.id, { num: curNum + 1 });
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            <Button
                              variant="outline-secondary"
                              className="rounded-circle"
                              size="sm"
                              style={{ width: "30.25px" }}
                              onClick={() => {
                                setProducts((products) => {
                                  if (!products) {
                                    return undefined;
                                  }
                                  return [
                                    ...products.filter(
                                      (target) => target.id !== product.id
                                    ),
                                  ];
                                });
                                setCart(product.id, { num: 0 });
                              }}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
            </div>
            <div
              className={`blocking-overlay${
                userStatus === "ALLOWED" && viewStatus === "VIEW"
                  ? " d-none"
                  : ""
              }`}
            ></div>
          </div>
          <hr />
          <div className="position-relative pt-2">
            <div>
              <h4 className="h5">備考</h4>
              <Form.Group className="mb-3" controlId="remains">
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Form.Group>
            </div>
            <div
              className={`blocking-overlay${
                userStatus === "ALLOWED" && viewStatus === "VIEW"
                  ? " d-none"
                  : ""
              }`}
            ></div>
          </div>
          <hr />
          <div className="position-relative mb-4">
            <Card>
              <Card.Body>
                <Card.Title>合計(税込)</Card.Title>
                <Card.Text>{getTotal().toLocaleString()} 円</Card.Text>
                <div className="d-grid gap-2">
                  <Button
                    variant="warning"
                    className={`${
                      products && products.length === 0 ? "disabled" : ""
                    }`}
                    onClick={() => request()}
                  >
                    見積もりへ
                  </Button>
                </div>
              </Card.Body>
            </Card>
            <div
              className={`blocking-overlay${
                userStatus === "ALLOWED" && viewStatus === "VIEW"
                  ? " d-none"
                  : ""
              }`}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

const Complated: FC = () => {
  return <>見積もりを依頼しました。</>;
};

const Quotation: FC = () => {
  const ajaxNonAuth = useAjax(false);
  const ajax = useAjax();
  const [userStatus, setUserStatus] = useState<UserStatus>("UNKNOWN");
  const [products, setProducts] = useState<(Product & { num: number })[]>();
  const [viewStatus, setViewStatus] = useState<ViewStatus>("NOTINITIALIZED");
  const [shippingAddresses, setShippingAddresses] = useState<ShippingAddress[]>(
    []
  );
  const [selected, setSelected] = useState<number>(0);
  const [modifyTarget, setModifyTarget] = useState<number>();

  const checkUserStatus = async () => {
    const user = getUser();
    if (!user || !user.roles.includes("member")) {
      setUserStatus("NOTSIGNINED");
      return "NOTSIGNINED";
    }
    const ret = await ajax.get<{
      status: "CONFIRMED" | "PENDING_VERIFICATION";
    }>("/api/member/oneself");
    if (ret.data.status !== "CONFIRMED") {
      setUserStatus("NOTALLOWED");
      return "NOTALLOWED";
    }
    setUserStatus("ALLOWED");
    return "ALLOWED";
  };

  useEffect(() => {
    (async () => {
      const carts = getCarts();
      if (Object.keys(carts).length === 0) {
        setProducts([]);
      } else {
        const products = await ajaxNonAuth.get<Product[]>(
          "/api/anyone/products",
          {
            params: {
              ids: Object.keys(carts).join(","),
            },
          }
        );
        setProducts(
          products.data.map((product) =>
            Object.assign(product, carts[product.id])
          )
        );
      }
      if ((await checkUserStatus()) !== "NOTSIGNINED") {
        setSelected((await loadShippingAddresses())[0].id);
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    })().catch((err) => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadShippingAddresses = async () => {
    const ret = await ajax.get<ShippingAddress[]>(
      "/api/member/oneself/shipping-addresses"
    );
    setShippingAddresses(ret.data);
    setViewStatus("VIEW");
    return ret.data;
  };

  return (
    <BasicPageBase mainClassName="quotation">
      <Helmet>
        <title>{title} | confidence cigar</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <QuotationContext.Provider
        value={{
          viewStatus,
          userStatus,
          products,
          shippingAddresses,
          selected,
          modifyTarget,
          checkUserStatus,
          setViewStatus: (status) => {
            setViewStatus(status);
          },
          setProducts,
          setSelected: (id) => setSelected(id),
          reloadShippingAddresses: loadShippingAddresses,
          setModifyTarget: (id) => setModifyTarget(id),
        }}
      >
        <Container className="mt-4">
          <h3 className="mb-3">お見積のリクエスト</h3>
          {viewStatus === "COMPLATED" ? <Complated /> : <Request />}
        </Container>
      </QuotationContext.Provider>
    </BasicPageBase>
  );
};

export default Quotation;
